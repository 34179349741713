import styled from "styled-components";
import { FaCheck } from "react-icons/fa";

interface Props {
  name: string;
  id: string;
  value: string;
  label: string;
  changed?: (data?: any) => void;
  checked?: boolean;
}

const FormControl = styled.div`
  display: flex;
  margin-bottom: 1rem;
  &:not(:last-child) {
    margin-right: 1.5rem;
  }
`;

const Label = styled.label`
  background-color: var(-white);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  border-radius: 4px;
  border: 2px solid var(--grey-light-2);
  margin-right: 1rem;
  &:hover {
    cursor: pointer;
  }
`;

const CheckInput = styled.input`
  display: none;

  &:not(:checked) ~ label .icon {
    color: transparent;
  }

  &:checked ~ label {
    border-color: var(--blue);
    background-color: var(--blue);
  }

  &:checked ~ label .icon {
    color: #fff;
  }
`;

const CheckBox = ({ label, name, id, value, changed, checked }: Props) => {
  return (
    <FormControl>
      <CheckInput
        type="checkbox"
        id={id}
        name={name}
        value={value}
        onChange={changed}
        checked={checked}
      />
      <Label htmlFor={id}>
        <FaCheck className="icon" />
      </Label>
      {label}
    </FormControl>
  );
};

export default CheckBox;
