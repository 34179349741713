import styled from "styled-components";
import Paragraph from "../Typography/Paragraph";
import IconWrapper from "../Wrappers/IconWrapper";
import Spacer from "./../Utils/Spacer";
interface Props {
  icon: React.ReactNode;
  value: number;
  name: string;
}
const Card = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  color: var(--grey-dark-1);
  border-radius: var(--radius-md);
  box-shadow: var(--shadow-smooth);
  height: 30rem;
  width: 22rem;
  flex-shrink: 0;
  margin: 2rem 0;
  &:first-child {
    margin-left: 0.5rem;
  }
  &:not(:last-child) {
    margin-right: 2rem;
  }
`;
const SummaryCard = ({ icon, name, value }: Props) => {
  return (
    <Card>
      <IconWrapper size="xxlg">{icon}</IconWrapper>
      <Spacer size="xlg" />
      <Paragraph>{name}</Paragraph>
      <Paragraph>{value}</Paragraph>
    </Card>
  );
};

export default SummaryCard;
