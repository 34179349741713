import IconWrapper from "../Wrappers/IconWrapper";
import { BottomWrapper, Nav, Navlink } from "./StylesNavigation";
import { RiHome2Line, RiSettings3Line } from "react-icons/ri";
import { FiUser, FiHeart } from "react-icons/fi";
import { isAuthenticated } from "../../api";
import { IoStorefrontOutline } from "react-icons/io5";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";

const BottomBar = () => {
  const { uiUpdate } = useContext(AppContext);
  const [updateUi, ,] = uiUpdate;
  const [user, setUser] = useState<IUser>();
  useEffect(() => {
    setUser(isAuthenticated().user);
  }, [updateUi]);
  return (
    <BottomWrapper>
      <Nav>
        <Navlink exact to="/">
          <IconWrapper size="md">
            <RiHome2Line />
          </IconWrapper>
        </Navlink>
        <Navlink to="/favorites">
          <IconWrapper size="md">
            <FiHeart />
          </IconWrapper>
        </Navlink>
        {user && Object.keys(user).length !== 0 ? (
          user.role === "seller" ? (
            <Navlink to="/my-grocery">
              <IconWrapper size="md">
                <IoStorefrontOutline />
              </IconWrapper>
            </Navlink>
          ) : user.role === "admin" ? (
            <Navlink to="/dashboard">
              <IconWrapper size="md">
                <RiSettings3Line />
              </IconWrapper>
            </Navlink>
          ) : null
        ) : null}

        <Navlink to="/profile">
          <IconWrapper size="md">
            <FiUser />
          </IconWrapper>
        </Navlink>
      </Nav>
    </BottomWrapper>
  );
};

export default BottomBar;
