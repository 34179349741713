import styled from "styled-components";
import Paragraph from "../components/Typography/Paragraph";
import Container from "../components/Wrappers/Container";
import Wrapper from "../components/Wrappers/Wrapper";
import IconWrapper from "../components/Wrappers/IconWrapper";
import Spacer from "../components/Utils/Spacer";
import { isAuthenticated, getMyFavoriteGroceries, getMe } from "../api";
import { useContext, useEffect, useState } from "react";
import GroceryCard from "../components/Layout/GroceryCard";
import { FaUserCog } from "react-icons/fa";
import ProfilePopup from "./../components/Layout/ProfilePopup";
import Loader from "./../components/Animations/Loader";
import Heading from "../components/Typography/Heading";
import { AppContext } from "../context/AppContext";
import FloatButton from "../components/Generics/FloatButton";
const GroceryGrid = styled.div`
  width: 100%;
  padding: 2rem 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  align-items: flex-start;
  gap: 2rem;
`;
const Content = styled.div`
  display: flex;
  align-items: center;
  background-color: var(--white);
  box-shadow: var(--shadow-smooth);
  border-radius: var(--radius-md);
  overflow: hidden;
`;
const Image = styled.img`
  width: 18.5rem;
  display: inline-block;
  /* border-radius: var(--radius-md); */
`;
const Info = styled.div`
  padding: 1rem;
  color: var(--grey-dark-1);
`;

const Profile = () => {
  // const [user, setUser] = useState({} as IUser);
  // const [groceries, setGroceries] = useState([] as IGrocery[]);

  const [popupShown, setPopupShown] = useState(false);
  const [loading, setLoading] = useState(false);
  const { favoriteData, userData } = useContext(AppContext);
  const [appUser, setAppUser] = userData;
  const [favoriteGroceries, setFavoriteGroceries] = favoriteData;
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Minha Conta | BAZARAQUI";
  }, []);
  useEffect(() => {
    const init = async () => {
      const { token } = isAuthenticated();
      if (!appUser || Object.keys(appUser).length === 0) {
        setLoading(true);
        const _user = await getMe(token);
        setAppUser(_user);
      } else {
        const _user = await getMe(token);
        setAppUser(_user);
      }
      if (!favoriteGroceries) {
        const res = await getMyFavoriteGroceries(appUser.id, token);
        setFavoriteGroceries([...res]);
      }
      setLoading(false);
    };
    init();
    // eslint-disable-next-line
  }, []);
  const handlePopup = () => {
    setPopupShown(true);
  };
  const handleClosePopup = () => {
    setPopupShown(false);
  };

  return (
    <Wrapper>
      {loading ? <Loader /> : null}

      <ProfilePopup close={handleClosePopup} visible={popupShown} />
      <Container>
        <Content>
          <Image src={appUser.photo} alt="nome do usuario" />
          <Info>
            <Paragraph weight="thin">{`${appUser.district}, ${appUser.address}`}</Paragraph>
            <Heading weight="bolder" size="md">
              {appUser.name}
            </Heading>
            <Paragraph weight="thin">+258 {appUser.phoneNumber}</Paragraph>
          </Info>
        </Content>
        <FloatButton clicked={handlePopup}>
          <IconWrapper>
            <IconWrapper size="md">
              <FaUserCog />
            </IconWrapper>
          </IconWrapper>
        </FloatButton>
        <Spacer size="md" />
        <Paragraph>Bancas Favoritas</Paragraph>
        <GroceryGrid>
          {favoriteGroceries.length ? (
            favoriteGroceries.map((grocery: IGrocery, i: number) => (
              <GroceryCard
                key={i}
                name={grocery.name}
                image={grocery.logo}
                description={grocery.about}
                slug={grocery.slug}
              />
            ))
          ) : (
            <Paragraph>Nenhuma banca favorita</Paragraph>
          )}
        </GroceryGrid>
      </Container>
    </Wrapper>
  );
};

export default Profile;
