import styled from "styled-components";
import Heading from "../components/Typography/Heading";
import Container from "../components/Wrappers/Container";
import Wrapper from "../components/Wrappers/Wrapper";
import SummaryCard from "../components/Layout/SummaryCard";
import { BiStore } from "react-icons/bi";
import { GoPlus } from "react-icons/go";
import Spacer from "../components/Utils/Spacer";
import ProductCard from "../components/Layout/ProductCard";
import { useContext, useEffect, useState } from "react";
import IconWrapper from "../components/Wrappers/IconWrapper";
import { useHistory } from "react-router-dom";
import Prompt from "../components/Generics/Prompt";
import { isAuthenticated, getMyGrocery, deleteProduct } from "../api";
import Loader from "./../components/Animations/Loader";
import { AppContext } from "../context/AppContext";
import FloatButton from "../components/Generics/FloatButton";
import Button from "../components/Generics/Button";
import Paragraph from "../components/Typography/Paragraph";

const Grid = styled.div`
  width: 100%;
  padding: 2rem 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  align-items: center;
  gap: 2rem;
`;

const Summary = styled.div`
  padding: 2rems;
  display: flex;
  width: 100%;
  overflow: auto;
  white-space: nowrap;
`;

const SellerGrocery = () => {
  const [selectedProduct, setSelectedProduct] = useState({} as IProduct);
  const [promptShown, setPromptShown] = useState(false);
  const [loading, setLoading] = useState(false);
  const { sellerData } = useContext(AppContext);
  const [
    productsSeller,
    setProductsSeller,
    sellerStatistics,
    setSellerStatistics,
    grocerySeller,
    setGrocerySeller,
  ] = sellerData;
  const { totalProducts } = sellerStatistics;
  const showPrompt = (product: IProduct) => {
    setSelectedProduct(product);
    setPromptShown(true);
  };
  const removePrompt = () => {
    setPromptShown(false);
  };
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Minha Banca | BAZARAQUI";
  }, []);
  useEffect(() => {
    const init = async () => {
      const { user } = isAuthenticated();
      if (!grocerySeller || Object.keys(grocerySeller).length === 0)
        setLoading(true);

      if (user) {
        const res = await getMyGrocery(user._id);
        setGrocerySeller({ ...grocerySeller, ...res });
        setSellerStatistics({
          ...sellerStatistics,
          totalProducts: res.products.length,
        });
        setProductsSeller([...res.products]);
      }
      setLoading(false);
    };
    init();

    // eslint-disable-next-line
  }, []);
  const handleUpdate = async () => {
    setLoading(true);
    const { token } = isAuthenticated();
    await deleteProduct(selectedProduct._id, token);
    let restOfProducts = productsSeller;

    restOfProducts = restOfProducts.filter(
      (product: IProduct) => product._id !== selectedProduct._id
    );
    setProductsSeller(restOfProducts);
    setSellerStatistics({
      ...sellerStatistics,
      totalProducts: restOfProducts.length,
    });
    setLoading(false);
  };

  return (
    <>
      <Wrapper>
        <Container>
          <Prompt
            onConfirm={handleUpdate}
            message={`Remover o produto: ${selectedProduct.name}?`}
            visible={promptShown}
            close={removePrompt}
          />
          <Heading size="xl" weight="bold">
            Sumário da {grocerySeller.name} ({grocerySeller.active ? "HABILITADA" : "DESABILITADA"})
          </Heading>
          <Summary>
            {/* <SummaryCard name="Visitas diarias" value={45} icon={<BiTrendingUp />} /> */}
            <SummaryCard
              name="produtos"
              value={totalProducts || 0}
              icon={<BiStore />}
            />
          </Summary>
          <Button
            color="alert"
            action={() => history.push("/update-grocery")}
          >
            Editar banca
          </Button>
          <Spacer />
          <Heading size="xl" weight="bold">
            Meus Produtos
          </Heading>
          <Grid>
            {productsSeller &&
              productsSeller.length ? productsSeller.map((product: IProduct, i: number) => (
                <ProductCard
                  key={i}
                  name={product.name}
                  image={product.image}
                  price={product.priceVariations}
                  page={`/update-product/${product.slug}`}
                  controls={{
                    delete: () => showPrompt(product),
                    update: () =>
                      history.push(`/update-product/${product.slug}`),
                  }}
                />
              )): <Paragraph>Nenhum Produto</Paragraph>}
          </Grid>
          <FloatButton
            clicked={() => {
              history.push("/add-product");
            }}
          >
            <IconWrapper size="md">
              <GoPlus />
            </IconWrapper>
          </FloatButton>
          {loading ? <Loader /> : null}
        </Container>
      </Wrapper>
    </>
  );
};

export default SellerGrocery;
