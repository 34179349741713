import styled, { keyframes } from "styled-components";
const Page = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.3);
`;
const spinnerOne = keyframes`
0%{
  transform: rotate(0deg);
  border-width: 10px;
}
50%{
  transform: rotate(180deg);
  border-width: 1px;
}
100%{
  transform: rotate(360deg);
  border-width: 10px;
}
`;
const spinnerTwo = keyframes`
0%{
  transform: rotate(0deg);
  border-width: 1px;
}
50%{
  transform: rotate(180deg);
  border-width: 10px;
}
100%{
  transform: rotate(360deg);
  border-width: 1px;
}
`;
const Spinners = styled.div`
  width: 10rem;
  height: 10rem;
  position: relative;
`;
const Spinner = styled.div`
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  height: 100%;
  border: 10px solid transparent;
  border-top-color: var(--green);
  border-radius: 50%;
  animation: ${spinnerOne} 1.2s linear infinite;
  &:nth-child(2) {
    border: 10px solid transparent;
    border-bottom-color: var(--green);
    animation: ${spinnerTwo} 1.2s linear infinite;
  }
`;

const Bouncer = () => {
  return (
    <Page>
      <Spinners>
        <Spinner />
        <Spinner />
      </Spinners>
    </Page>
  );
};

export default Bouncer;
