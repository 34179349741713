import InputText from "../components/Form/InputText";
import Heading from "../components/Typography/Heading";
import Spacer from "../components/Utils/Spacer";
import { Buttons, CheckBoxes, LoginForm } from "../components/Form/FormStyles";
import ImagePicker from "../components/Form/ImagePicker";
import { useEffect, useState, useContext } from "react";
import ImagePlaceholder from "./../assets/placeholder150.png";
import Outline from "../components/Generics/Outline";
// import { getGrocery } from "../api";
import Loader from "./../components/Animations/Loader";
import Paragraph from "../components/Typography/Paragraph";
import { AppContext } from "../context/AppContext";
import {
  getMarkets,
  getMyGrocery,
  isAuthenticated,
  updateMyGrocery,
} from "../api";
import Select from "react-select";
import CheckBox from "../components/Form/CheckBox";
import Alert from "../components/Generics/Alert";
import TextArea from "../components/Form/TextArea";
import Wrapper from "../components/Wrappers/Wrapper";

const payments = [
  "ABSA",
  "M-Pesa",
  "E-Mola",
  "M-Kesh",
  "Ponto24",
  "BancoUnico",
  "FNB",
  "MozaBanco",
  "StandardBank",
  "BCI",
  "MillenniumBim",
  "BancABC",
];
const UpdateGrocery = () => {
  const { sellerData } = useContext(AppContext);
  const [grocerySeller, setGrocerySeller] = sellerData;
  const { homeData } = useContext(AppContext);
  const [homeMarkets, setHomeMarkets] = homeData;
  const [values, setValues] = useState({} as IGrocery);
  const [groceryPhoto, setGroceryPhoto] = useState({
    url: ImagePlaceholder,
  } as any);

  const [loading, setLoading] = useState(false);
  const [market, setMarket] = useState("");
  const [alertShown, setAlertShown] = useState({
    message: "",
    status: false,
    type: "",
  });
  const { paymentMethods, contact, name, about, _id } = values;
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Actualizar Produto | BAZARAQUI";
  }, []);
  useEffect(() => {
    const init = async () => {
      const { user } = isAuthenticated();
      if (!grocerySeller || Object.keys(grocerySeller).length === 0)
        setLoading(true);

      if (user) {
        const res = await getMyGrocery(user._id);
        setGrocerySeller({ ...grocerySeller, ...res });
        setValues({ ...grocerySeller, ...res });
        setMarket(res.market._id);
        setGroceryPhoto({ ...groceryPhoto, url: res.logo });
        const res2 = await getMarkets();
        setHomeMarkets(res2);
      }
      setLoading(false);
    };
    init();

    // eslint-disable-next-line
  }, []);
  const handleCloseAlert = () => {
    setAlertShown({ ...alertShown, message: "", status: false, type: "" });
  };
  const handleShowAlert = (
    message: string,
    type: "alert" | "cancel" | "success"
  ) => {
    setAlertShown({
      ...alertShown,
      message: message,
      status: true,
      type: "type",
    });
  };
  const handleChangePhoto = (event: any) => {
    setGroceryPhoto({
      image: event.target.files[0],
      url: URL.createObjectURL(event.target.files[0]),
    });
    setValues({ ...values, logo: event.target.files[0] });
  };

  const options: any[] | undefined = homeMarkets.map((el: any) => {
    return { label: el.name, value: el.id };
  });
  const optionChange = (option: any) => {
    setMarket(option.value);
  };
  const handleChange =
    (input: string) => (event: React.FormEvent<HTMLInputElement>) => {
      setValues({
        ...values,
        [input]: event.currentTarget.value,
      });
    };

  const paymentMethodsHandler = (event: any) => {
    let sellerMethods: any = paymentMethods;
    const method = event.target.value;

    const addMethod = () => {
      sellerMethods.push(method);
      //removing duplicates
      sellerMethods = sellerMethods.filter(
        (a: string, b: string) => sellerMethods.indexOf(a) === b
      );
    };

    const removeMethod = () => {
      sellerMethods = sellerMethods.filter((el: any) => el !== method);
    };

    event.target.checked ? addMethod() : removeMethod();

    setValues({ ...values, paymentMethods: sellerMethods });
  };

  const handleSubmit = (type: string) => async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    const { token } = isAuthenticated();
    const formData = new FormData();

    if (type === "data") {
      formData.append("market", market);
      formData.append("name", name);
      formData.append("contact", contact);
      formData.append("about", about);
      formData.append("paymentMethods", JSON.stringify(paymentMethods));
    } else {
      formData.append("logo", groceryPhoto.image);
    }
    const res = await updateMyGrocery(_id, formData, token);

    if (res.status === "error" || res.status === "fail") {
      handleShowAlert("Erro ao actualizar!", "cancel");
    } else {
      handleShowAlert("Actualizado com sucesso", "success");
    }
    setLoading(false);
  };
  return (
    <Wrapper form>
      <Alert
        message={alertShown.message}
        close={handleCloseAlert}
        visible={alertShown.status}
        type={alertShown.type}
      />
      <LoginForm onSubmit={handleSubmit("data")}>
        <Heading size="xl" weight="bold">
          Alterar dados da banca: {grocerySeller.name}
        </Heading>
        <Spacer size="xxlg" />
        <Paragraph size="lg" weight="normal">
          Mercado(actual {values.market && values.market.name})
        </Paragraph>
        <Select
          options={options}
          onChange={optionChange}
          defaultValue={{ label: "Seleccione um mercado" }}
        />

        <InputText
          type="text"
          name="groceryName"
          placeholder="insira o nome da sua banca"
          label="Banca"
          changed={handleChange("name")}
          defaultValue={name}
        />
        <InputText
          type="text"
          name="contact"
          placeholder="insira o contacto da sua banca"
          label="Contacto"
          changed={handleChange("contact")}
          defaultValue={contact}
        />
        <TextArea
          name="about"
          placeholder="insira o contacto da sua banca"
          label="Descrição(Produtos vendidos)"
          changed={handleChange("about")}
          defaultValue={about}
        />
        <Paragraph size="lg" weight="normal">
          Métodos de pagamento
        </Paragraph>
        <CheckBoxes>
          {payments.map((payment, i) => (
            <CheckBox
              name="paymentMethodType"
              id={payment}
              value={payment}
              label={payment}
              changed={paymentMethodsHandler}
              checked={paymentMethods && paymentMethods.includes(payment)}
              key={i}
            />
          ))}
        </CheckBoxes>
        <Buttons>
          <Outline color="alert">alterar dados</Outline>
        </Buttons>
      </LoginForm>
      {loading ? <Loader /> : null}

      <LoginForm onSubmit={handleSubmit("logo")}>
        <ImagePicker
          label="foto da banca"
          photoUrl={groceryPhoto.url}
          changed={handleChangePhoto}
        />
        <Buttons>
          <Outline color="alert">alterar foto</Outline>
        </Buttons>
      </LoginForm>
    </Wrapper>
  );
};

export default UpdateGrocery;
