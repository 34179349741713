import styled from "styled-components";
import Heading from "../components/Typography/Heading";
import Container from "../components/Wrappers/Container";
import Wrapper from "../components/Wrappers/Wrapper";
import SummaryCard from "../components/Layout/SummaryCard";
import { BiStore, BiTrendingUp } from "react-icons/bi";
import Spacer from "../components/Utils/Spacer";
import ProductCard from "../components/Layout/ProductCard";
import { useContext, useEffect, useState } from "react";
import MarketImagePlaceholder from "./../assets/logo.png";
import { useHistory } from "react-router-dom";
import { HiOutlineUserGroup, HiUserGroup } from "react-icons/hi";
import { RiUserUnfollowLine } from "react-icons/ri";
import { Buttons, RadioGroup } from "../components/Form/FormStyles";
import RadioButton from "../components/Form/RadioButton";
import Loader from "../components/Animations/Loader";
import {
  isAuthenticated,
  getUserStatistics,
  getGroceryStatistics,
  getUsers,
  getMarkets,
  getGroceries,
  getProducts,
} from "../api";
import GroceryCard from "../components/Layout/GroceryCard";
import Paragraph from "../components/Typography/Paragraph";
import { AppContext } from "../context/AppContext";
import Button from "../components/Generics/Button";

const Grid = styled.div`
  width: 100%;
  padding: 2rem 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  align-items: center;
  gap: 2rem;
`;

const GroceryGrid = styled.div`
  width: 100%;
  padding: 2rem 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  align-items: flex-start;
  gap: 2rem;
`;

const Image = styled.img`
  width: 18.5rem;
  display: inline-block;
`;
const Info = styled.div`
  padding: 1rem;
  color: var(--grey-dark-1);
`;

const Summary = styled.div`
  padding: 2rems;
  display: flex;
  width: 100%;
  overflow: auto;
  white-space: nowrap;
`;
const Content = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: var(--white);
  box-shadow: var(--shadow-smooth);
  border-radius: var(--radius-md);
  overflow: hidden;
  &:not(:last-child) {
    margin-bottom: 2rem;
  }
`;
const RighButton = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
`;

const Dashboard = () => {
  const [currentContent, setCurrentContent] = useState("products");
  const [loading, setLoading] = useState(false);
  const { adminData } = useContext(AppContext);
  const [
    productsAdmin,
    setProductsAdmin,
    groceriesAdmin,
    setGroceriesAdmin,
    sellersAdmin,
    setSellersAdmin,
    marketsAdmin,
    setMarketsAdmin,
    statistics,
    setStatistics,
  ] = adminData;

  const {
    totalGroceries,
    totalSellers,
    totalBuyers,
    totalBlockedUsers,
    totalBlockedGroceries,
    totalProducts,
    totalMarkets,
    // dailyVisits,
  } = statistics;
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Minha Banca | BAZARAQUI";
  }, []);
  const showProducts = () => (
    <Grid>
      {productsAdmin &&
        productsAdmin.map((product: IProduct, i: number) => (
          <ProductCard
            key={i}
            name={product.name}
            image={product.image}
            price={product.priceVariations}
            page="/edit-product/product"
            action={() =>
              history.push(`/seller-and-grocery/${product.grocery.manager}`)
            }
          />
        ))}
    </Grid>
  );
  const showGroceries = () => (
    <GroceryGrid>
      {groceriesAdmin &&
        groceriesAdmin.map((grocery: IGrocery, i: number) => (
          <GroceryCard
            key={i}
            name={grocery.name}
            image={grocery.logo}
            description={grocery.about}
            slug={grocery.slug}
            clicked={() => history.push(`/grocery/${grocery.slug}`)}
          />
        ))}
    </GroceryGrid>
  );
  const showSellers = () => (
    <>
      {sellersAdmin &&
        sellersAdmin.map((seller: IUser, i: number) => (
          <Content
            key={i}
            onClick={() => history.push(`/seller-and-grocery/${seller._id}`)}
          >
            <Image src={seller.photo} alt="nome do usuario" />
            <Info>
              <Paragraph weight="thin">{`${seller.district}, ${seller.address}`}</Paragraph>
              <Heading weight="bolder" size="md">
                {seller.name}
              </Heading>
              <Paragraph weight="thin">+258 {seller.phoneNumber}</Paragraph>
              {groceriesAdmin &&
                groceriesAdmin.map((grocery: IGrocery, iterator: number) =>
                  grocery.manager === seller._id ? (
                    <span key={iterator}>
                      <Paragraph weight="thin">{grocery.name}</Paragraph>
                      <Paragraph weight="thin">{grocery.market.name}</Paragraph>
                    </span>
                  ) : null
                )}
            </Info>
          </Content>
        ))}
    </>
  );

  const showMarkets = () => (
    <>
      <RighButton>
        <Button color="alert" action={() => history.push("/add-market")}>
          Adicionar Mercado
        </Button>
      </RighButton>
      <Spacer />
      {marketsAdmin &&
        marketsAdmin.map((market: IMarket, i: number) => (
          <Content key={i}>
            <Image
              src={MarketImagePlaceholder}
              alt="nome do usuario"
              onClick={() => history.push(`/market/${market.id}`)}
            />
            <Info>
              <Paragraph weight="thin">{`${market.district}, ${market.address}`}</Paragraph>
              <Heading weight="bolder" size="md">
                {market.name}
              </Heading>
              {/* <Paragraph weight="thin">{`256 bancas`}</Paragraph> */}

              {/* <Paragraph weight="thin">+258 {market.phoneNumber}</Paragraph> */}
            </Info>
          </Content>
        ))}
    </>
  );
  const showContent = () => {
    switch (currentContent) {
      case "products":
        return showProducts();
      case "groceries":
        return showGroceries();
      case "sellers":
        return showSellers();
      case "markets":
        return showMarkets();
      default:
        break;
    }
  };
  useEffect(() => {
    const init = async () => {
      // showLoading();
      const { token } = isAuthenticated();
      if (token) {
        let $products: any, $markets: any;
        if (!productsAdmin || productsAdmin.length === 0) {
          setLoading(true);
          $products = await getProducts();
          setProductsAdmin($products);
        }
        if (!marketsAdmin || marketsAdmin.length === 0) {
          // setLoading(true);
          $markets = await getMarkets();
          setMarketsAdmin($markets);
          setLoading(false);
        }
        if (!groceriesAdmin || groceriesAdmin.length === 0) {
          // setLoading(true);
          const $groceries = await getGroceries();
          setGroceriesAdmin($groceries);
        }

        if (!sellersAdmin || sellersAdmin.length === 0) {
          // setLoading(true);
          const $sellers = await getUsers(token, "?role=seller");
          setSellersAdmin($sellers);
        }
        if (!statistics || Object.keys(statistics).length === 0) {
          // setLoading(true);
        }
        const userStats = await getUserStatistics(token);
        let sellers = 0;
        let buyers = 0;
        let blockedUsers = 0;
        let groceries = 0;
        let blockedGroceries = 0;
        // userStats;
        userStats.overviewStats.forEach((userStats: any) => {
          if (userStats._id === "seller") sellers = userStats.totalUsers;
          if (userStats._id === "buyer") buyers = userStats.totalUsers;
        });
        if (userStats.blockedStats[0])
          blockedUsers = userStats.blockedStats[0].totalUsers;

        // grocery stats
        const groceryStats = await getGroceryStatistics(token);
        if (groceryStats.overviewStats[0])
          groceries = groceryStats.overviewStats[0].totalGroceries;
        if (groceryStats.blockedStats[0])
          blockedGroceries = groceryStats.blockedStats[0].totalGroceries;
        setStatistics({
          ...statistics,
          totalSellers: sellers,
          totalBuyers: buyers,
          totalBlockedUsers: blockedUsers,
          totalGroceries: groceries,
          totalProducts: $products ? $products.length : productsAdmin.length,
          totalMarkets: $markets ? $markets.length : marketsAdmin.length,
          totalBlockedGroceries: blockedGroceries,
        });

        setCurrentContent("products");
      }
      setLoading(false);
    };
    init();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Wrapper>
        <Container>
          {loading ? <Loader /> : null}
          <Heading size="xl" weight="bold">
            Sumário do BazarAqui
          </Heading>
          <Summary>
            <SummaryCard
              name="Mercados"
              value={totalMarkets || 0}
              icon={<BiTrendingUp />}
            />
            <SummaryCard
              name="Produtos"
              value={totalProducts || 0}
              icon={<BiTrendingUp />}
            />
            <SummaryCard
              name="Bancas"
              value={totalGroceries || 0}
              icon={<BiStore />}
            />
            <SummaryCard
              name="Bloqueadas"
              value={totalBlockedGroceries || 0}
              icon={<BiStore />}
            />
            <SummaryCard
              name="Compradores"
              value={totalBuyers || 0}
              icon={<HiOutlineUserGroup />}
            />
            <SummaryCard
              name="Vendedores"
              value={totalSellers || 0}
              icon={<HiUserGroup />}
            />
            <SummaryCard
              name="Bloqueados"
              value={totalBlockedUsers || 0}
              icon={<RiUserUnfollowLine />}
            />
          </Summary>
          <Spacer />
          <Heading size="lg" weight="bold">
            gerência
          </Heading>

          <RadioGroup>
            <RadioButton
              name="type"
              label="produtos"
              id="products"
              value="products"
              changed={() => setCurrentContent("products")}
            />
            <RadioButton
              name="type"
              label="bancas"
              id="grocery"
              value="grocery"
              changed={() => setCurrentContent("groceries")}
            />
            <RadioButton
              name="type"
              label="vendedores"
              id="seller"
              value="seller"
              changed={() => setCurrentContent("sellers")}
            />
            <RadioButton
              name="type"
              label="mercados"
              id="market"
              value="market"
              changed={() => setCurrentContent("markets")}
            />
          </RadioGroup>

          <Spacer />
          <Buttons>
            <Button color="alert" action={() => history.push("/create-admin")}>
              criar admin
            </Button>
          </Buttons> 

         
          {showContent()}
        </Container>
      </Wrapper>
    </>
  );
};

export default Dashboard;
