import styled from "styled-components";

export const InputBox = styled.div`
  color: var(--grey-dark);
  font-weight: 400;
  font-size: var(--font-md);
  width: 100%;
  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }
`;
export const TArea = styled.textarea`
  width: 100%;
  max-width: 100%;
  min-width: 99.9%;
  display: inline-block;
  border: 1px solid var(--grey-light-3);
  border-radius: var(--radius-md);
  background-color: var(--white);
  min-height: var(--input-height);
  height: calc(var(--input-height) * 2);
  padding: 1rem;
  margin-right: 1rem;
  font: inherit;
  transition: all 0.1s;
  &:focus {
    outline: none;
    box-shadow: 0 0 0 1px var(--yellow);
    border-color: var(--yellow);
  }
`;
export const InputField = styled.input`
  width: 100%;
  display: inline-block;
  border: 1px solid var(--grey-light-3);
  border-radius: var(--radius-md);
  background-color: var(--white);
  height: var(--input-height);
  padding: 0 1rem;
  margin-right: 1rem;
  font: inherit;
  transition: all 0.1s;
  &:focus {
    outline: none;
    box-shadow: 0 0 0 1px var(--yellow);
    border-color: var(--yellow);
  }
`;
export const RadioGroup = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const Label = styled.label`
  font-weight: 500;
  font-size: 1.6rem;
  text-transform: capitalize;
  display: inline-block;
  margin-bottom: 0.5rem;
`;

export const LoginWrapper = styled.div`
  min-height: 75vh;
  width: 100%;
  padding: 1.8rem;
`;
export const LoginForm = styled.form`
  border-radius: var(--radius-md);
  font-size: var(--font-md);
  color: var(--grey-dark);
  max-width: 50rem;
  margin: 0 auto;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: space-around;
  max-width: 50rem;
  width: 100%;
  margin-bottom: 1.5rem;
`;

export const CheckBoxes = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
