import InputText from "../components/Form/InputText";
import Heading from "../components/Typography/Heading";
import Spacer from "../components/Utils/Spacer";
import {
  Buttons,
  Label,
  LoginForm,
  RadioGroup,
} from "../components/Form/FormStyles";
import RadioButton from "../components/Form/RadioButton";
import ImagePicker from "../components/Form/ImagePicker";
import { useEffect, useState } from "react";
import ImagePlaceholder from "./../assets/placeholder150.png";
import { useParams } from "react-router-dom";
import Outline from "../components/Generics/Outline";
import {
  isAuthenticated,
  getProduct,
  getCategories,
  updateProduct,
} from "../api";
import Loader from "./../components/Animations/Loader";
import Alert from "../components/Generics/Alert";
import TextArea from "../components/Form/TextArea";
import Wrapper from "../components/Wrappers/Wrapper";

interface IParams {
  slug: string;
}
const UpdateProduct = () => {
  const [values, setValues] = useState({} as IProduct);
  const [product, setProduct] = useState({} as IProduct);
  const [productPhoto, setProductPhoto] = useState({
    url: ImagePlaceholder,
  } as any);
  const { slug } = useParams<IParams>();
  const [categories, setCategories] = useState([] as ICategory[]);
  const { name, priceVariations, image, category, description } = values;
  const [loading, setLoading] = useState(false);
  const [alertShown, setAlertShown] = useState({
    message: "",
    status: false,
    type: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Actualizar Produto | BAZARAQUI";
  }, []);
  useEffect(() => {
    const init = async () => {
      setLoading(true);
      const res = await getProduct(slug);
      setProduct(res);
      setValues(res);
      setProductPhoto({ ...productPhoto, url: res.image });

      const cats = await getCategories();
      setCategories(cats);
      setLoading(false);
    };
    init();
    // eslint-disable-next-line
  }, [slug]);
  const handleCloseAlert = () => {
    setAlertShown({ ...alertShown, message: "", status: false, type: "" });
  };
  const handleShowAlert = (
    message: string,
    type: "alert" | "cancel" | "success"
  ) => {
    setAlertShown({
      ...alertShown,
      message: message,
      status: true,
      type: "type",
    });
  };
  const handleChangePhoto = (event: any) => {
    setProductPhoto({
      image: event.target.files[0],
      url: URL.createObjectURL(event.target.files[0]),
    });
    setValues({ ...values, image: event.target.files[0] });
  };
  const handleChangePriceVariations = (event: any) => {
    const name = event.target.name;
    const price = event.target.value;
    const prices = priceVariations.map((p) =>
      p.name === name ? { ...p, price: price * 1 } : p
    );

    setValues({ ...values, priceVariations: prices });
  };
  const handleChange =
    (input: string) => (event: React.FormEvent<HTMLInputElement>) => {
      setValues({
        ...values,
        [input]: event.currentTarget.value,
      });
    };
  const handleSubmit =
    (type: string) => async (event: React.SyntheticEvent) => {
      event.preventDefault();
      setLoading(true);
      const { token } = isAuthenticated();
      const formData = new FormData();

      if (type === "data") {
        formData.append("name", name);
        formData.append("priceVariations", JSON.stringify(priceVariations));
        formData.append("description", description);
        formData.append("category", category);
      } else {
        formData.append("image", image);
      }

      const res = await updateProduct(formData, product._id, token);

      if (res.status === "error" || res.status === "fail") {
        handleShowAlert("Erro ao actualizar!", "cancel");
      } else {
        handleShowAlert("Actualizado com sucesso", "success");
        setProduct(res);
        setValues(res);
      }
      setLoading(false);
    };
  return (
    <Wrapper form>
      <Alert
        message={alertShown.message}
        close={handleCloseAlert}
        visible={alertShown.status}
        type={alertShown.type}
      />
      <LoginForm onSubmit={handleSubmit("data")}>
        <Heading size="xl" weight="bold">
          Actualizar Produto: {product.name}
        </Heading>
        <Spacer size="xxlg" />
        <InputText
          type="text"
          name="name"
          placeholder={name || "Produto"}
          label="Nome do produto"
          defaultValue={name}
          mandatory
          changed={handleChange("name")}
        />
        <TextArea
          name="description"
          placeholder={name || "Produto"}
          label="Descrição do produto"
          defaultValue={description}
          mandatory
          changed={handleChange("description")}
        />
        {priceVariations &&
          priceVariations.map((el, i) => (
            <InputText
              type="number"
              key={i}
              name={el.name}
              placeholder={el.price.toString()}
              defaultValue={el.price}
              label={el.name}
              changed={handleChangePriceVariations}
            />
          ))}

        <Label>Categoria</Label>
        <RadioGroup>
          {/* eslint-disable-next-line */}
          {categories &&
            categories.map((category, i) =>
              category._id === product.category ? (
                <RadioButton
                  key={i}
                  name="currentCategory"
                  label={category.name}
                  id={category._id}
                  value={category._id}
                  changed={() => {}}
                  checked
                />
              ) : (
                <></>
              )
            )}
        </RadioGroup>
        <RadioGroup>
          {/* eslint-disable-next-line */}
          {categories &&
            categories.map((category, i) =>
              category._id !== product.category ? (
                <RadioButton
                  key={i}
                  name="category"
                  label={category.name}
                  id={category._id}
                  value={category._id}
                  changed={handleChange("category")}
                />
              ) : (
                <></>
              )
            )}
        </RadioGroup>
        <Spacer size="md" />
        <Buttons>
          <Outline color="alert">Actualizar dados</Outline>
        </Buttons>
      </LoginForm>
      <LoginForm onSubmit={handleSubmit("image")}>
        <ImagePicker
          label="foto do produto"
          photoUrl={productPhoto.url}
          changed={handleChangePhoto}
        />
        <Spacer size="md" />
        <Buttons>
          <Outline color="alert">Actualizar foto</Outline>
        </Buttons>
      </LoginForm>

      {loading ? <Loader /> : null}
    </Wrapper>
  );
};

export default UpdateProduct;
