import styled from "styled-components";
import InputText from "../components/Form/InputText";
import Button from "../components/Generics/Button";
import Heading from "../components/Typography/Heading";
import Spacer from "../components/Utils/Spacer";
import { Label, LoginForm, RadioGroup } from "../components/Form/FormStyles";
import RadioButton from "../components/Form/RadioButton";
import ImagePicker from "../components/Form/ImagePicker";
import { useEffect, useState } from "react";
import ImagePlaceholder from "./../assets/placeholder150.png";
import {
  getGroceries,
  getCategories,
  isAuthenticated,
  createProduct,
} from "../api";
import Loader from "./../components/Animations/Loader";
import { useHistory } from "react-router-dom";
import RSelect from "../components/Form/RSelect";
import Alert from "../components/Generics/Alert";
import Paragraph from "../components/Typography/Paragraph";
import TextArea from "../components/Form/TextArea";
import Wrapper from "../components/Wrappers/Wrapper";

const Buttons = styled.div`
  display: flex;
  justify-content: space-around;
`;
const AddProduct = () => {
  const [myGrocery, setMyGrocery] = useState({} as IGrocery);
  const [categories, setCategories] = useState([] as ICategory[]);
  const [selectedVariations, setSelectedVariations] = useState([] as string[]);
  const [values, setValues] = useState({
    name: "",
    description: "",
    priceVariations: [] as {}[],
    category: "",
  });
  const [productPhoto, setProductPhoto] = useState({
    url: ImagePlaceholder,
  } as any);
  const [loading, setLoading] = useState(false);
  const [alertShown, setAlertShown] = useState({
    message: "",
    status: false,
    type: "",
  });
  const { token } = isAuthenticated();
  const { name, description, priceVariations, category } = values;
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Adicionar Produto | BAZARAQUI";
  }, []);
  useEffect(() => {
    const init = async () => {
      setLoading(true);
      //handling favorites
      const { user } = isAuthenticated();
      if (user) {
        const res = await getGroceries(`manager=${user._id}`);
        setMyGrocery(res[0]);
        const cats = await getCategories();
        setCategories(cats);
      }
      setLoading(false);
    };
    init();
  }, []);
  const handleChangePhoto = (event: any) => {
    setProductPhoto({
      image: event.target.files[0],
      url: URL.createObjectURL(event.target.files[0]),
    });
  };
  const handleCloseAlert = () => {
    setAlertShown({ ...alertShown, message: "", status: false, type: "" });
  };
  const handleShowAlert = (
    message: string,
    type: "alert" | "cancel" | "success"
  ) => {
    setAlertShown({
      ...alertShown,
      message: message,
      status: true,
      type: "type",
    });
  };
  const handleChange =
    (input: string) => (event: React.FormEvent<HTMLInputElement>) => {
      setValues({
        ...values,
        [input]: event.currentTarget.value,
      });
    };

  const handleSubmit = async (e: React.SyntheticEvent) => {
    // setLoading(true);
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", productPhoto.image);
    formData.append("name", name);
    formData.append("description", description);
    formData.append("category", category);
    formData.append("grocery", myGrocery._id);
    formData.append("priceVariations", JSON.stringify(priceVariations));

    const res = await createProduct(formData, token);
    if (res.status === "error" || res.status === "fail") {
      handleShowAlert(res.message, "cancel");
    } else {
      handleShowAlert("Produto adicionado com sucesso", "success");
      history.push("/my-grocery");
    }
    setLoading(false);
  };
  const $priceVariations = [
    { name: "Kg" },
    { name: "Unidade" },
    { name: "Caixa" },
    { name: "Pequeno" },
    { name: "Médio" },
    { name: "Grande" },
    { name: "Saco" },
    { name: "Saco Pequeno" },
    { name: "Saco Médio" },
    { name: "Saco Grande" },
    { name: "Molho" },
    { name: "Molho Pequeno" },
    { name: "Molho Médio" },
    { name: "Molho Grande" },
    { name: "Plástico" },
  ];

  return (
    <Wrapper form>
      <Alert
        message={alertShown.message}
        close={handleCloseAlert}
        visible={alertShown.status}
        type={alertShown.type}
      />
      <LoginForm onSubmit={handleSubmit}>
        <Heading size="xl" weight="bold">
          Adicionar Produto A {myGrocery && myGrocery.name}
        </Heading>
        <Paragraph>Todos os campos são obrigatórios</Paragraph>
        <Spacer size="xxlg" />
        <InputText
          type="text"
          name="productName"
          placeholder="Produto"
          label="Nome do produto"
          changed={handleChange("name")}
          mandatory
        />
        <RSelect
          id="prices"
          label="Formas de venda"
          values={$priceVariations.map((el: any) => el.name)}
          defaultSelected="Seleccione formas de venda do produto"
          changed={(event: any) => {
            let varExists = false;
            selectedVariations.forEach((variation: any) => {
              if (variation === event.name) varExists = true;
            });
            varExists
              ? handleShowAlert("Por favor escolha outra forma", "cancel")
              : setSelectedVariations([...selectedVariations, event.name]);
          }}
        />

        {selectedVariations.map((el: string, i: number) => (
          <InputText
            key={i}
            type="number"
            name={el}
            placeholder="Preço"
            label={`Preço para ${el}`}
            changed={() => {}}
            onBlur={(event) =>
              setValues({
                ...values,
                priceVariations: [
                  ...priceVariations,
                  { name: el, price: event.target.value },
                ],
              })
            }
          />
        ))}
        <TextArea
          name="description"
          placeholder="Descrição do produto"
          label="Descrição"
          changed={handleChange("description")}
          mandatory
        />

        <ImagePicker
          label="foto do produto"
          photoUrl={productPhoto.url}
          changed={handleChangePhoto}
        />

        <Label>Categoria</Label>
        <RadioGroup>
          {categories.map((category, i) => (
            <RadioButton
              key={i}
              name="category"
              label={category.name}
              id={category._id}
              value={category._id}
              changed={handleChange("category")}
            />
          ))}
        </RadioGroup>
        <Spacer size="md" />
        <Buttons>
          <Button color="alert">Adicionar</Button>
        </Buttons>
      </LoginForm>
      {loading ? <Loader /> : null}
    </Wrapper>
  );
};

export default AddProduct;
