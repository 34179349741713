import { useEffect, useState } from "react";
import InputText from "../components/Form/InputText";
import Button from "../components/Generics/Button";
import Paragraph from "../components/Typography/Paragraph";
import Heading from "../components/Typography/Heading";
import Spacer from "../components/Utils/Spacer";
import { forgotPassword } from "../api";
import { Link } from "react-router-dom";
import Alert from "../components/Generics/Alert";
import { Buttons, LoginForm } from "../components/Form/FormStyles";
import Wrapper from "../components/Wrappers/Wrapper";

const PasswordForgot = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [alertShown, setAlertShown] = useState({
    message: "",
    status: false,
    type: "",
  });
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Perdi a Senha | BAZARAQUI";
  }, []);
  const handleCloseAlert = () => {
    setAlertShown({ ...alertShown, message: "", status: false, type: "" });
  };
  const handleShowAlert = (
    message: string,
    type: "alert" | "cancel" | "success"
  ) => {
    setAlertShown({
      ...alertShown,
      message: message,
      status: true,
      type: type,
    });
  };
  const handleChange = (arg: string) => {
    setPhoneNumber(arg);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const res = await forgotPassword({ phoneNumber });

    if (res.status === "error" || res.status === "fail") {
      handleShowAlert("erro ao recuperar a senha", "cancel");
    } else {
      handleShowAlert(res.message, "success");
    }
  };

  return (
    <Wrapper form>
      <Alert
        message={alertShown.message}
        close={handleCloseAlert}
        visible={alertShown.status}
        type={alertShown.type}
      />
      <LoginForm onSubmit={handleSubmit}>
        <Heading size="xl" weight="bold">
          Recuperar Senha
        </Heading>
        <Spacer size="xxlg" />
        <Paragraph weight="normal" size="sm">
          Para recuperar a sua senha insira o número de celular que usou para
          registrar a sua conta, ou contacte ao gestor do seu mercado para ta
          efeito.
        </Paragraph>
        <Spacer size="xxlg" />
        <InputText
          type="number"
          name="contacto"
          placeholder="Insira o seu número"
          label="Número de celular"
          changed={(event: any) => handleChange(event.target.value)}
        />
        <Spacer size="xlg" />
        <Buttons>
          <Button color="alert">recuperar</Button>
        </Buttons>
        <Spacer size="md" />
        <Paragraph size="sm" weight="thin">
          Lembrou a senha?
          <Link to="/login"> iniciar sessao</Link>
        </Paragraph>
      </LoginForm>
    </Wrapper>
  );
};

export default PasswordForgot;
