import { GrEdit, GrTrash } from "react-icons/gr";
import styled from "styled-components";
import Heading from "../Typography/Heading";
import Paragraph from "../Typography/Paragraph";
import { RedColor } from "../Utils/TextColorUtils";
import IconWrapper from "../Wrappers/IconWrapper";

interface Props {
  name: string;
  image: string;
  price: IPrices[];
  page?: string;
  controls?: { update?: (param?: any) => void; delete: (param?: any) => void };
  action?: () => void;
}
const Card = styled.div`
  cursor: pointer;
  text-decoration: none;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  border-radius: var(--radius-md);
  overflow: hidden;
  box-shadow: var(--shadow-smooth);
`;
const ProductImage = styled.img`
  width: 100%;
  margin-bottom: 2rem;
  border-radius: 2px;
`;
const ProductInfo = styled.div`
  width: 100%;
  font-size: var(--font-md);
  color: var(--grey-dark);
  display: flex;
  justify-content: space-between;
`;
const Controls = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: space-between;
`;
const Controllers = styled.div`
  /* margin-right: auto; */
  display: flex;
  margin-left: auto;
  width: 7rem;
  justify-content: space-between;
`;
const Info = styled.div`
  width: 100%;
`;

const ProductCard = ({ name, image, price, page, action, controls }: Props) => {
  price.sort((a, b) => (a.price > b.price ? 1 : b.price > a.price ? -1 : 0));
  return (
    <Card onClick={action}>
      <ProductImage src={image} alt="Product image" loading="lazy" />
      <ProductInfo>
        <Info>
          <Paragraph weight="normal" size="md">
            {name}
          </Paragraph>
          <Controls>
            <Heading size="md" weight="bolder">
              {price.length > 1
                ? `${price[0].price}-${price[price.length - 1].price}`
                : price[0].price}{" "}
              MT
            </Heading>
            {controls ? (
              <Controllers>
                <span onClick={controls.update}>
                  <IconWrapper size="md">
                    <GrEdit />
                  </IconWrapper>
                </span>

                <RedColor onClick={controls.delete}>
                  <IconWrapper size="md">
                    <GrTrash />
                  </IconWrapper>
                </RedColor>
              </Controllers>
            ) : null}
          </Controls>
        </Info>
      </ProductInfo>
    </Card>
  );
};

export default ProductCard;
