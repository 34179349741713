const provinces = [
  { _id: 1, name: "Maputo Cidade" },
  { _id: 2, name: "Maputo Provincia" },
  // { _id: 3, name: "Gaza" },
  // { _id: 4, name: "Inhambane" },
  // { _id: 5, name: "Sofala" },
  // { _id: 6, name: "Manica" },
  // { _id: 7, name: "Zambezia" },
  // { _id: 8, name: "Tete" },
  // { _id: 9, name: "Nampula" },
  // { _id: 10, name: "Cabo Delegado" },
  // { _id: 11, name: "Niassa" },
];

export default provinces;
