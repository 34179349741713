import styled from "styled-components";
import InputText from "../components/Form/InputText";
import Heading from "../components/Typography/Heading";
import Spacer from "../components/Utils/Spacer";
import { isAuthenticated, updateMe, getMe } from "../api";
import React, { useEffect, useState } from "react";
import Alert from "../components/Generics/Alert";
import { useHistory, useParams } from "react-router-dom";
import ImagePicker from "../components/Form/ImagePicker";
import { Buttons, LoginForm } from "../components/Form/FormStyles";
import Loader from "./../components/Animations/Loader";
import Outline from "../components/Generics/Outline";
import Wrapper from "../components/Wrappers/Wrapper";

interface IParams {
  content: "data" | "photo";
}

const LightColor = styled.span`
  color: var(--grey-dark-1);
`;
const UserUpdate = () => {
  const history = useHistory();
  const { token } = isAuthenticated();
  const [user, setUser] = useState({} as IUser);
  const [alertShown, setAlertShown] = useState({
    message: "",
    status: false,
    type: "",
  });
  const [userNewData, setUserNewData] = useState({} as IUser);
  const [profilePhoto, setProfilePhoto] = useState({ url: user.photo } as any);
  const { content } = useParams<IParams>();
  const [loading, setLoading] = useState(false);
  const { name, phoneNumber, email, address } = userNewData;
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Alterar Meus Dados | BAZARAQUI";
  }, []);
  useEffect(() => {
    const init = async () => {
      setLoading(true);
      const res = await getMe(token);
      setUser(res);
      setProfilePhoto({ ...profilePhoto, url: res.photo });
      setLoading(false);
    };

    init();
    //eslint-disable-next-line
  }, []);
  const handleChange =
    (input: string) => (event: React.FormEvent<HTMLInputElement>) => {
      setUserNewData({
        ...userNewData,
        [input]: event.currentTarget.value,
      });
    };
  const handleCloseAlert = () => {
    setAlertShown({ ...alertShown, message: "", status: false, type: "" });
  };
  const handleShowAlert = (
    message: string,
    type: "alert" | "cancel" | "success"
  ) => {
    setAlertShown({
      ...alertShown,
      message: message,
      status: true,
      type: type,
    });
  };
  const userUpdateHandler = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (
      Object.keys(userNewData).length === 0 &&
      userNewData.constructor === Object
    ) {
      return handleShowAlert("Nada a ser atualizado!", "alert");
    }
    if (!userNewData.phoneNumber.match(/^(84|85|86|87|83|82)\d{7}$/))
      return handleShowAlert(
        "ERRO: o número de celular tem de ser Moçambicano",
        "cancel"
      );
    setLoading(true);
    await updateMe(
      {
        name,
        phoneNumber,
        address,
        email,
      },
      token
    );
    setLoading(false);
    handleShowAlert("Dados atualizados com sucesso!", "success");
  };
  const handleChangePhoto = (event: any) => {
    setProfilePhoto({
      image: event.target.files[0],
      url: URL.createObjectURL(event.target.files[0]),
    });
  };
  const handleUpload = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("photo", profilePhoto.image);
    await updateMe(formData, token);
    handleShowAlert("Foto atualizada com sucesso!", "success");
    setLoading(false);
    history.push("/profile");
  };
  switch (content) {
    case "data":
      return (
        <Wrapper form>
          <Alert
            type="alert"
            message={alertShown.message}
            visible={alertShown.status}
            close={handleCloseAlert}
          />
          <LoginForm onSubmit={userUpdateHandler}>
            <Heading size="xl" weight="bold">
              Minha conta
            </Heading>
            <Spacer size="xlg" />
            <Heading weight="bold" size="lg">
              <LightColor>Dados pessoais </LightColor>
            </Heading>
            <Spacer size="md" />
            <InputText
              type="text"
              name="name"
              placeholder=""
              defaultValue={user.name}
              label="nome"
              changed={handleChange("name")}
            />

            <InputText
              type="text"
              name="address"
              placeholder=""
              defaultValue={user.address}
              label="endereco"
              changed={handleChange("address")}
            />

            <InputText
              type="number"
              name="phone"
              placeholder=""
              defaultValue={user.phoneNumber}
              label="número de celular"
              changed={handleChange("phoneNumber")}
            />

            <InputText
              type="text"
              name="email"
              placeholder=""
              defaultValue={user.email}
              label="email(Opcional)"
              changed={handleChange("email")}
            />

            <Spacer size="xlg" />
            <Buttons>
              <Outline color="alert">alterar Dados</Outline>
            </Buttons>
          </LoginForm>
          {loading ? <Loader /> : null}
        </Wrapper>
      );

    case "photo":
      return (
        <Wrapper form>
          <LoginForm onSubmit={handleUpload}>
            <ImagePicker
              label="alterar foto"
              photoUrl={profilePhoto.url}
              changed={handleChangePhoto}
            />
            <Outline color="alert">Atualizar</Outline>
          </LoginForm>
          {loading ? <Loader /> : null}
        </Wrapper>
      );
    default:
      break;
  }
};

export default UserUpdate;
