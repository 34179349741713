import React from "react";
import styled from "styled-components";
interface Props {
  action?: () => void;
  children: React.ReactNode;
  color?: "success" | "cancel" | "info" | "alert";
  size?: "sm" | "lg" | "full";
}
const typeToColor = (color?: "success" | "cancel" | "info" | "alert") => {
  switch (color) {
    case "success":
      return "--green";
    case "cancel":
      return "--red";
    case "info":
      return "--blue";
    case "alert":
      return "--yellow";
    default:
      return "--grey-light-1";
  }
};
const Btn = styled.button`
  width: 25rem;
  text-transform: uppercase;
  cursor: pointer;
  border: 2px solid var(${({ color }: Props) => typeToColor(color)});
  border-radius: var(--radius-md);
  height: var(--input-height);
  font-weight: 600;
  display: inline-block;
  font-size: 1.5rem;
  color: var(${({ color }: Props) => typeToColor(color)});
  background-color: transparent;
  text-align: center;
  padding: 0 2rem;
  transition: all 0.3s;
  @media only screen and (max-width: 600px) {
    width: ${({ size }: Props) => (size === "full" ? "100%" : "unset")};
  }
  &:hover {
    background-color: var(${({ color }: Props) => typeToColor(color)});
    color: var(--white);
  }
  &:focus {
    border-color: var(${({ color }: Props) => typeToColor(color)});
  }
`;

const Outline = ({ size, action, children, color }: Props) => {
  return (
    <Btn onClick={action} color={color} size={size}>
      {children}
    </Btn>
  );
};

export default Outline;
