import styled from "styled-components";
import Heading from "../Typography/Heading";
import Paragraph from "../Typography/Paragraph";
import Link from "../Generics/Link";
interface Props {
  name: string;
  image: string;
  description: string;
  slug: string;
  clicked?: (param?: any) => void;
}
const Card = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  background-color: white;
  border-radius: var(--radius-md);
  overflow: hidden;
  box-shadow: var(--shadow-smooth);
`;
const GroceryImage = styled.img`
  width: calc(100% - 1.5rem);
  margin-bottom: 2rem;
`;
const GroceryInfo = styled.div`
  min-height: 8.5rem;
  width: 100%;
  font-size: var(--font-md);
  color: var(--grey-dark);
`;

const GroceryCard = ({ name, image, description, slug, clicked }: Props) => {
  return (
    <Card onClick={clicked}>
      <GroceryImage src={image} alt="Grocery image" />
      <GroceryInfo>
        <Heading size="sm" weight="normal">
          {name}
        </Heading>
        <Paragraph weight="thin" size="sm">
          <b>Descrição: </b> {description}
        </Paragraph>
      </GroceryInfo>
      <Link to={`/grocery/${slug}`} color="alert">
        Visitar &rarr;
      </Link>
    </Card>
  );
};

export default GroceryCard;
