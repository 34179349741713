import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const NavWrapper = styled.div`
  z-index: 60;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--white);
  border-bottom: 1px solid var(--grey-light);
`;
export const BottomWrapper = styled.div`
  z-index: 60;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: var(--white);
  border-top: 1px solid var(--grey-light);
  @media only screen and (min-width: 600px) {
    display: none;
  }
`;
export const Nav = styled.nav`
  display: flex;
  max-width: 1000px;
  margin: 0 auto;
  padding: 0.5rem 1.5rem;
  height: 8rem;
  align-items: center;
  justify-content: space-between;
  font-size: var(--font-md);
  font-weight: 400;
`;

export const Navlink = styled(NavLink)`
  cursor: pointer;
  position: relative;
  color: var(--grey-dark);
  text-decoration: none;
  transition: var(--transition-normal);
  display: flex;
  align-items: center;
  &:hover {
    color: var(--yellow);
  }
  &.active {
    color: var(--yellow);
  }

  margin-right: 2rem;
`;
export const NavButton = styled.div`
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    color: var(--yellow);
  }
`;
export const Logo = styled.img`
  height: 4rem;
`;
export const SearchBar = styled.form`
  display: flex;
  align-items: center;
  justify-self: center;
  margin: 0 auto;
  font-size: var(--font-md);
`;
export const Search = styled.input`
  width: calc(100% - 30px);
  display: inline-block;
  border: 1px solid var(--grey-light-3);
  border-radius: var(--radius-md);
  background-color: var(--white);
  height: var(--input-height);
  padding: 0 1rem;
  margin-right: 1rem;
  font: inherit;
  transition: all 0.1s;
  &:focus {
    outline: none;
    box-shadow: 0 0 0 1px var(--yellow);
    border-color: var(--yellow);
  }
`;
export const Profile = styled.div`
  display: flex;
  align-items: center;
`;
export const UserPhoto = styled.img`
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 50%;
  margin-right: 0.5rem;
`;
export const Cart = styled.div`
  position: relative;
`;
export const Badge = styled.span`
  font-size: var(--font-xsm);
  font-weight: 500;
  border-radius: 20rem;
  height: 2rem;
  width: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--grey-dark);
  color: var(--white);
  position: absolute;
  top: -1rem;
  right: -1.5rem;
`;
export const Cta = styled.span`
  text-transform: uppercase;
  border: none;
  padding: 0.8rem;
  background-color: var(--green);
  border-radius: var(--radius-md);
  display: inline-block;
  text-decoration: none;
  color: var(--white);
  align-self: flex-end;
  transition: all 0.3s;
  &:hover {
    color: var(--white);
    background-color: var(--green-active);
  }
`;
export const Cta2 = styled.span`
  text-transform: uppercase;
  border: none;
  padding: 0.8rem;
  background-color: var(--yellow);
  border-radius: var(--radius-md);
  display: inline-block;
  text-decoration: none;
  color: var(--white);
  align-self: flex-end;
  transition: all 0.3s;
  &:hover {
    background-color: var(--yellow-active);
  }
`;
export const Links = styled.div`
  display: flex;
  align-items: center;
  @media only screen and (max-width: 600px) {
    display: none;
  }
`;
