import { useState, useEffect } from "react";
import styled from "styled-components";
import ProductCard from "../components/Layout/ProductCard";
import Heading from "../components/Typography/Heading";
import Paragraph from "../components/Typography/Paragraph";
import Container from "../components/Wrappers/Container";
import Wrapper from "../components/Wrappers/Wrapper";
import { findProducts, getCategories } from "../api";
import { useParams } from "react-router-dom";
import Loader from "./../components/Animations/Loader";
import ProductPopup from "../components/Layout/ProductPopup";
import RadioButton from "../components/Form/RadioButton";
import { RadioGroup } from "../components/Form/FormStyles";

interface IParams {
  query: string;
}

const Grid = styled.div`
  width: 100%;
  padding: 2rem 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  align-items: center;
  gap: 2rem;
`;

const Search = () => {
  const [products, setProducts] = useState({} as IProduct[]);
  const [productsHelper, setProductsHelper] = useState([] as IProduct[]);
  const [categories, setCategories] = useState([] as ICategory[]);
  const [loading, setLoading] = useState(false);
  const [popupShown, setPopupShown] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({} as IProduct);
  const { query } = useParams<IParams>();
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Pesquisar | BAZARAQUI";
  }, []);
  useEffect(() => {
    const init = async () => {
      const cats = await getCategories();
      setCategories(cats);
    };
    init();
  }, []);
  useEffect(() => {
    const init = async () => {
      setLoading(true);
      const res = await findProducts(query, "any");
      setProducts(res);
      setProductsHelper(res);
      setLoading(false);
    };
    init();
  }, [query]);
  const handlePopup = (product: IProduct) => {
    setSelectedProduct(product);
    setPopupShown(true);
  };
  const handleClosePopup = () => {
    setPopupShown(false);
  };
  const handleFilter = (event: React.FormEvent) => {
    event.preventDefault();
  };
  const filterHandler = (event: any) => {
    const category = event.target.id;
    let filteredProducts = productsHelper;

    filteredProducts = filteredProducts.filter(
      (el: IProduct) => el.category === category
    );

    category !== "All"
      ? setProducts(filteredProducts)
      : setProducts(productsHelper);
  };
  return (
    <Wrapper>
      {popupShown ? (
        <ProductPopup
          grocery={selectedProduct.grocery.name}
          productName={selectedProduct.name}
          marketName={selectedProduct.grocery.market.name}
          image={selectedProduct.image}
          priceVariation={selectedProduct.priceVariations}
          contact={selectedProduct.grocery.contact}
          paymentMethods={selectedProduct.grocery.paymentMethods}
          visible={popupShown}
          close={handleClosePopup}
        />
      ) : null}
      {loading ? <Loader /> : null}
      <Container>
        <Paragraph>Pesquisou por "{query}"</Paragraph>
        <Paragraph weight="thin">
          Foram encontrados ({productsHelper.length}) resultados
        </Paragraph>

        <Paragraph weight="bold">Filtrar categorias</Paragraph>

        <form onSubmit={handleFilter}>
          <RadioGroup>
            <RadioButton
              id="All"
              name="category"
              value="Todas"
              label="Todas"
              changed={filterHandler}
            />
            {categories.map((category, i) => (
              <RadioButton
                label={category.name}
                key={i}
                id={category._id}
                name="category"
                value={category.name}
                changed={filterHandler}
              />
            ))}
          </RadioGroup>
        </form>

        <Heading size="lg" weight="bolder">
          Produtos
        </Heading>
        <Grid>
          {products ? (
            products.length ? (
              products.map((product, i) => (
                <ProductCard
                  key={i}
                  page={`/product?q=${product.slug}`}
                  name={product.name}
                  image={product.image}
                  price={product.priceVariations}
                  action={() => {
                    handlePopup(product);
                  }}
                />
              ))
            ) : (
              <Paragraph>Nenhum produto foi encontrado</Paragraph>
            )
          ) : (
            <> </>
          )}
        </Grid>
      </Container>
    </Wrapper>
  );
};

export default Search;
