import styled from "styled-components";
import InputText from "../components/Form/InputText";
import Button from "../components/Generics/Button";
import dstrt from "./../utils/cities";
import RSelect from "../components/Form/RSelect";
import Heading from "../components/Typography/Heading";
import prvncss from "./../utils/provinces";
import Spacer from "../components/Utils/Spacer";
import { LoginForm } from "../components/Form/FormStyles";
import { useEffect, useState } from "react";
import Alert from "../components/Generics/Alert";
import Loader from "../components/Animations/Loader";
import { isAuthenticated, getMarkets, updateMarket } from "../api";
import { useParams } from "react-router-dom";
import Wrapper from "../components/Wrappers/Wrapper";

interface IParams {
  id: string;
}

const Buttons = styled.div`
  display: flex;
  justify-content: space-around;
`;
const AddProduct = () => {
  const [values, setValues] = useState({} as IMarket);
  const [districts, setDistricts] = useState([] as any[]);
  const [loading, setLoading] = useState(false);
  const { id } = useParams<IParams>();
  const [alertShown, setAlertShown] = useState({
    message: "",
    status: false,
    type: "",
  });

  const { name, province, district, address } = values;
  const handleCloseAlert = () => {
    setAlertShown({ ...alertShown, message: "", status: false, type: "" });
  };
  const handleShowAlert = (
    message: string,
    type: "alert" | "cancel" | "success"
  ) => {
    setAlertShown({
      ...alertShown,
      message: message,
      status: true,
      type: "type",
    });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Actualizar Mercado | BAZARAQUI";
  }, []);

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      const res = await getMarkets(`/${id}`);
      setValues(res);
      setLoading(false);
    };
    init();
    // eslint-disable-next-line
  }, [id]);

  const handleProvince = (option: any) => {
    setDistricts(
      dstrt.filter((el) => {
        return el.pname === option.name;
      })
    );
    setValues({ ...values, province: option.name });
  };

  const handleChange =
    (input: string) => (event: React.FormEvent<HTMLInputElement>) => {
      setValues({ ...values, [input]: event.currentTarget.value });
    };

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);
    const { token } = isAuthenticated();

    const res = await updateMarket(
      id,
      {
        name,
        province,
        district,
        address,
      },
      token
    );

    if (res.status === "error" || res.status === "fail") {
      handleShowAlert(res.message, "cancel");
    } else {
      handleShowAlert("Mercado actualizado com sucesso", "success");
    }
    setLoading(false);
  };
  return (
    <Wrapper>
      <Alert
        message={alertShown.message}
        close={handleCloseAlert}
        visible={alertShown.status}
        type={alertShown.type}
      />
      <LoginForm onSubmit={handleSubmit}>
        <Heading size="xl" weight="bolder">
          Actualizar Mercado
        </Heading>
        <Spacer size="xxlg" />
        <InputText
          type="text"
          name="marketName"
          placeholder="mercado"
          label="Nome do mercado"
          changed={handleChange("name")}
          defaultValue={name}
        />
        <RSelect
          label={`província (actual ${province})`}
          id="provincia"
          values={prvncss.map((el) => el.name)}
          changed={handleProvince}
          defaultSelected="Seleccione uma província"
        />
        <RSelect
          id="municipio"
          label={`município (actual ${district})`}
          values={districts.map((el) => el.name)}
          changed={(event: any) =>
            setValues({ ...values, district: event.name })
          }
          defaultSelected="Seleccione um município"
        />
        <InputText
          type="text"
          name="location"
          placeholder="rua/avenida"
          label="Localização do mercado"
          changed={handleChange("address")}
          defaultValue={address}
        />

        <Spacer size="md" />
        <Buttons>
          <Button color="alert">Actualizar</Button>
        </Buttons>
      </LoginForm>
      {loading ? <Loader /> : null}
    </Wrapper>
  );
};

export default AddProduct;
