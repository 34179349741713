import { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import qs from "qs";
import InputText from "../components/Form/InputText";
import Button from "../components/Generics/Button";
import Paragraph from "../components/Typography/Paragraph";
import Heading from "../components/Typography/Heading";
import Spacer from "../components/Utils/Spacer";
import { Buttons, LoginForm } from "../components/Form/FormStyles";
import { resetPassword, authenticate } from "../api";
import Alert from "../components/Generics/Alert";
import { RedColor } from "../components/Utils/TextColorUtils";
import Wrapper from "../components/Wrappers/Wrapper";

const PasswordReset = (props: any) => {
  const [formData, setFormData] = useState({} as any);
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [alertShown, setAlertShown] = useState({
    message: "",
    status: false,
    type: "",
  });
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Recuperar senha | BAZARAQUI";
  }, []);

  const handleCloseAlert = () => {
    setAlertShown({ ...alertShown, message: "", status: false, type: "" });
  };
  const handleShowAlert = (
    message: string,
    type: "alert" | "cancel" | "success"
  ) => {
    setAlertShown({
      ...alertShown,
      message: message,
      status: true,
      type: type,
    });
  };
  const handleDataChange = (event: React.FormEvent<HTMLInputElement>) => {
    setPasswordMatch(true);
    setFormData({
      ...formData,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (formData.password !== formData.passwordConfirm)
      return setPasswordMatch(false);

    const url = qs.parse(props.location.search, {
      ignoreQueryPrefix: true,
    }).url;

    const res = await resetPassword(url, formData);
    if (res.status === "fail" || res.status === "error") {
      handleShowAlert(res.message, "cancel");
    } else {
      authenticate({ token: res.token, user: res.data.user });
      handleShowAlert("Senha alterada com sucesso", "success");
      history.push("/");
    }
  };
  return (
    <Wrapper form>
      <Alert
        message={alertShown.message}
        close={handleCloseAlert}
        visible={alertShown.status}
        type={alertShown.type}
      />
      <LoginForm onSubmit={handleSubmit}>
        <Heading size="xl" weight="bold">
          Recuperar Senha
        </Heading>
        <Spacer size="xxlg" />
        <Paragraph weight="normal" size="sm">
          Insira uma nova senha para a sua conta
        </Paragraph>
        <Spacer size="xxlg" />
        <InputText
          type="password"
          name="password"
          placeholder="A senha deve conter no mínimo 8 caracteres"
          label="Nova senha"
          mandatory
          changed={handleDataChange}
        />
        <InputText
          type="password"
          name="passwordConfirm"
          placeholder="Repetir a senha anterior"
          label="Repetir a senha"
          mandatory
          changed={handleDataChange}
        />
        {!passwordMatch ? (
          <Paragraph size="sm" weight="thin">
            <RedColor>Senhas devem ser iguais!</RedColor>
          </Paragraph>
        ) : null}
        <Spacer size="xlg" />
        <Buttons>
          <Button color="alert">alterar senha</Button>
        </Buttons>
        <Spacer size="md" />
        <Paragraph size="sm" weight="thin">
          Lembra-se da senha? <Link to="/login">iniciar sessão</Link>
        </Paragraph>
      </LoginForm>
    </Wrapper>
  );
};

export default PasswordReset;
