import React, { useState, useEffect } from "react";
import InputText from "../components/Form/InputText";
import Button from "../components/Generics/Button";
import Heading from "../components/Typography/Heading";
import prvncss from "./../utils/provinces";
import dstrt from "./../utils/cities";
import Spacer from "../components/Utils/Spacer";
import { isAuthenticated, createAdmin } from "./../api";
import Loader from "./../components/Animations/Loader";
import Alert from "../components/Generics/Alert";
import {
  LoginForm,
  Buttons,
} from "../components/Form/FormStyles";
import RSelect from "../components/Form/RSelect";
import Wrapper from "../components/Wrappers/Wrapper";


const Signup = () => {
  const [loading, setLoading] = useState(false);
  const [alertShown, setAlertShown] = useState({
    message: "",
    status: false,
    type: "",
  });
  const [districts, setDistricts] = useState([] as any[]);
  const [values, setValues] = useState({
    name: "",
    phoneNumber: "",
    province: "",
    district: "",
    address: "",
    password: "",
    passwordConfirm: "",
  });
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Criar Admin | BAZARAQUI";
  }, []);

  const {
    name,
    phoneNumber,
    province,
    district,
    address,
    password,
    passwordConfirm,
  } = values;

  const handleCloseAlert = () => {
    setAlertShown({ ...alertShown, message: "", status: false, type: "" });
  };
  const handleShowAlert = (
    message: string,
    type: "alert" | "cancel" | "success"
  ) => {
    setAlertShown({
      ...alertShown,
      message: message,
      status: true,
      type: "type",
    });
  };

  const handleChange = (input: any) => (event: any) => {
    setValues({
      ...values,
      [input]: event.target.value,
    });
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    const { token } = isAuthenticated();
    
    if (!phoneNumber.match(/^(84|85|86|87|83|82)\d{7}$/))
      return handleShowAlert(
        "ERRO: o número de celular tem de ser Moçambicano",
        "cancel"
      );
    const res = await createAdmin({
      name,
      phoneNumber,
      province,
      district,
      address,
      password,
      passwordConfirm,
    }, token);
    setLoading(false);
    if (res.status === "error" || res.status === "fail") {
      if (res.message.includes("E11000"))
        return handleShowAlert(
          "O número do celular inserido já foi usado!",
          "cancel"
        );
      handleShowAlert(res.message, "cancel");
    } else {
      handleShowAlert("conta criada com sucesso", "success");
    }
  };
  const handleProvince = (option: any) => {
    setDistricts(
      dstrt.filter((el) => {
        return el.pname === option.name;
      })
    );
    setValues({ ...values, province: option.name });
  };


  return (
    <Wrapper form>
      <Alert
        message={alertShown.message}
        close={handleCloseAlert}
        visible={alertShown.status}
        type={alertShown.type}
      />
      {loading ? <Loader /> : null}

      <LoginForm onSubmit={handleSubmit}>
        <Heading size="xl" weight="bold">
          Criar Administrador
        </Heading>
        <Spacer size="xxlg" />

        <InputText
          type="text"
          name="name"
          placeholder="Insira o seu nome completo"
          label="nome"
          changed={handleChange("name")}
          mandatory
        />
        <RSelect
          label="província"
          id="provincia"
          values={prvncss.map((el) => el.name)}
          changed={handleProvince}
          defaultSelected="Seleccione uma província"
        />
        <RSelect
          id="provincia"
          label="município"
          values={districts.map((el) => el.name)}
          defaultSelected="Seleccione um município "
          changed={(event: any) =>
            setValues({ ...values, district: event.name })
          }
        />
        <InputText
          type="text"
          name="address"
          placeholder="Insira o seu endereço"
          label="endereço"
          changed={handleChange("address")}
          mandatory
        />
        <InputText
          type="number"
          name="phone"
          placeholder="Insira o seu número de celular"
          label="número de celular"
          changed={handleChange("phoneNumber")}
          mandatory
        />
        <InputText
          type="password"
          name="password"
          placeholder="Insira a sua senha"
          label="senha"
          changed={handleChange("password")}
          mandatory
        />
        <InputText
          type="password"
          name="passwordCoonfirm"
          placeholder="Insira novamente a sua senha"
          label="Repetir a senha"
          changed={handleChange("passwordConfirm")}
          mandatory
        />
        <Spacer size="xlg" />
        <Buttons>
          <Button color="alert">Criar</Button>
        </Buttons>
        <Spacer size="md" />
      </LoginForm>
    </Wrapper>
  );
};

export default Signup;
