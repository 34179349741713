import Select from "react-select";
import { InputBox, Label } from "./FormStyles";

interface Props {
  values: string[];
  label: string;
  id: string;
  defaultSelected?: string;
  placeholder?: string;
  changed: (option: any) => void;
}
const RSelect = ({
  values,
  label,
  changed,
  id,
  defaultSelected,
  placeholder,
}: Props) => {
  const options = values.map((el) => {
    return { name: el, label: el };
  });
  return (
    <InputBox>
      <Label htmlFor={id}>{label}</Label>
      <Select
        options={options}
        onChange={changed}
        id={id}
        defaultValue={{ label: defaultSelected, name: defaultSelected }}
        placeholder={placeholder}
      />
    </InputBox>
  );
};

export default RSelect;
