import { useEffect } from "react";
import styled from "styled-components";
import Paragraph from "../components/Typography/Paragraph";
import Container from "../components/Wrappers/Container";
import Wrapper from "../components/Wrappers/Wrapper";

const Card = styled.div`
  padding: 1.8rem;
  margin-top: 4rem;
  background-color: var(--white);
  box-shadow: var(--shadow-smooth);
  text-align: center;
  padding-bottom: 4rem;
`;

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `Sobre nós | Bazaraqui`;
    // eslint-disable-next-line
  }, []);
  return (
    <Wrapper>
      <Container>
        <Card>
          <Paragraph weight="normal">
            BazarAqui é uma plataforma de comércio online de produtos agrícolas
            para vendedores em mercados na cidade de Maputo no âmbito do
            programa “RESPONSIVE GOVERNANCE FOR COVID-19 STRATEGIC PLAN”,
            ​​financiado pelo PNUD, desenvolvido pela PRO-G e implementado pelo
            Espaço de Inovação de Eduardo Universidade de Mondlane, com a
            colaboração de 3 parceiros, nomeadamente, a Câmara Municipal de
            Maputo, a Associação Nacional dos Municípios de Moçambique e a
            Comissão de Vendedores do Mercado.
          </Paragraph>
        </Card>
      </Container>
    </Wrapper>
  );
};

export default About;
