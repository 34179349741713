import styled from "styled-components";
import Paragraph from "../components/Typography/Paragraph";
import Container from "../components/Wrappers/Container";
import Wrapper from "../components/Wrappers/Wrapper";
import Spacer from "../components/Utils/Spacer";
import { isAuthenticated, getUsers, getMyGrocery } from "../api";
import { useEffect, useState } from "react";
import Loader from "./../components/Animations/Loader";
import Heading from "../components/Typography/Heading";
import { Link, useParams } from "react-router-dom";
import { DarkColor } from "../components/Utils/TextColorUtils";
import { RadioGroup } from "../components/Form/FormStyles";
import RadioButton from "../components/Form/RadioButton";
import ProductCard from "../components/Layout/ProductCard";

interface Iparams {
  id: string;
}
const GroceryGrid = styled.div`
  width: 100%;
  padding: 2rem 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  align-items: flex-start;
  gap: 2rem;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  background-color: var(--white);
  box-shadow: var(--shadow-smooth);
  border-radius: var(--radius-md);
  overflow: hidden;
`;
const Image = styled.img`
  width: 18.5rem;
  display: inline-block;
`;
const Info = styled.div`
  padding: 1rem;
  color: var(--grey-dark-1);
`;

const Profile = () => {
  const [seller, setSeller] = useState({} as IUser);
  const [grocery, setGrocery] = useState({} as IGrocery);
  const [loading, setLoading] = useState(false);
  const { id } = useParams<Iparams>();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Minha Conta | BAZARAQUI";
  }, []);
  useEffect(() => {
    const init = async () => {
      setLoading(true);
      const { token } = isAuthenticated();
      // eslint-disable-next-line
      const $seller = await getUsers(token, `\/${id}`);
      setSeller($seller);

      const $grocery = await getMyGrocery(id);
      setGrocery($grocery);
      setLoading(false);
    };
    init();
  }, [id]);

  return (
    <Wrapper>
      {loading ? <Loader /> : null}

      <Container>
        <Content>
          <Image src={seller.photo} alt="nome do usuario" />
          <Info>
            <Paragraph weight="thin">{seller.district}, {seller.address}</Paragraph>
            <Heading weight="bolder" size="md">
              {seller.name}
            </Heading>
            <Paragraph><DarkColor>Banca: </DarkColor>
              <Link to={`/grocery/${grocery.slug}}`}>{grocery.name}, </Link>
              <Link to={`/market/${grocery.market && grocery.market.id}`}>{grocery.market && grocery.market.name}</Link> </Paragraph>
            <Spacer />
            <Paragraph>
              <DarkColor>Pagamentos: </DarkColor> {grocery.paymentMethods && grocery.paymentMethods.map(method => `${method} `)}
            </Paragraph>
            <Paragraph>
              <DarkColor>Contacto: </DarkColor>+258 {seller.phoneNumber}
            </Paragraph>
          </Info>
        </Content>

        <Spacer size="md" />
        <Heading size="md" weight="bold">
          Actividade de venda
        </Heading>
        <RadioGroup>
          <RadioButton
            name="userControl"
            label="Habilitar"
            value="enable"
            id="enable"
            changed={() => { }}
          />
          <RadioButton
            name="userControl"
            label="Desabilitar"
            value="disable"
            id="disable"
            changed={() => { }}
          />
        </RadioGroup>
        <Spacer />
        <Heading weight="bold" size="md">
          Produtos
        </Heading>
        <GroceryGrid>
          {grocery.products && grocery.products.length ? grocery.products.map(product => (
            <ProductCard
              name={product.name}
              image={product.image}
              price={product.priceVariations}
              page="/edit-product/product"
              action={() => { }}
            />
          )) : <Paragraph>Nenhum Produto</Paragraph>}
        </GroceryGrid>
      </Container>
    </Wrapper>
  );
};

export default Profile;
