const cities = [
  { _id: "1", name: "Maputo Cidade", pname: "Maputo Cidade" },
  { _id: "2", name: "Boane", pname: "Maputo Provincia" },
  { _id: "3", name: "Magude", pname: "Maputo Provincia" },
  { _id: "4", name: "Moamba", pname: "Maputo Provincia" },
  { _id: "5", name: "Manhica", pname: "Maputo Provincia" },
  { _id: "6", name: "Marracuene", pname: "Maputo Provincia" },
  { _id: "7", name: "Matola", pname: "Maputo Provincia" },
  { _id: "8", name: "Matutuine", pname: "Maputo Provincia" },
  { _id: "9", name: "Namaacha", pname: "Maputo Provincia" },
  { _id: "10", name: "Bilene", pname: "Gaza" },
  { _id: "11", name: "Chibuto", pname: "Gaza" },
  { _id: "12", name: "Chicualacuala", pname: "Gaza" },
  { _id: "13", name: "Chigubo", pname: "Gaza" },
  { _id: "14", name: "Chokwe", pname: "Gaza" },
  { _id: "15", name: "Chongoene", pname: "Gaza" },
  { _id: "16", name: "Guija", pname: "Gaza" },
  { _id: "17", name: "Limpopo", pname: "Gaza" },
  { _id: "18", name: "Mabalane", pname: "Gaza" },
  { _id: "19", name: "Manjakaze", pname: "Gaza" },
  { _id: "20", name: "Mapai", pname: "Gaza" },
  { _id: "21", name: "Massangena", pname: "Gaza" },
  { _id: "22", name: "Massingir", pname: "Gaza" },
  { _id: "23", name: "Xai-xai", pname: "Gaza" },
  { _id: "24", name: "Funhalouro", pname: "Inhambane" },
  { _id: "25", name: "Govuro", pname: "Inhambane" },
  { _id: "26", name: "Homoine", pname: "Inhambane" },
  { _id: "27", name: "Inhambane", pname: "Inhambane" },
  { _id: "28", name: "Inharrime", pname: "Inhambane" },
  { _id: "29", name: "Inhassouro", pname: "Inhambane" },
  { _id: "30", name: "Jangamo", pname: "Inhambane" },
  { _id: "31", name: "Mabote", pname: "Inhambane" },
  { _id: "32", name: "Massinga", pname: "Inhambane" },
  { _id: "33", name: "Maxixe", pname: "Inhambane" },
  { _id: "34", name: "Morrumbene", pname: "Inhambane" },
  { _id: "35", name: "Panda", pname: "Inhambane" },
  { _id: "36", name: "Vilanculos", pname: "Inhambane" },
  { _id: "37", name: "Zavala", pname: "Inhambane" },
  { _id: "38", name: "Beira", pname: "Sofala" },
  { _id: "39", name: "Buzi", pname: "Sofala" },
  { _id: "40", name: "Caia", pname: "Sofala" },
  { _id: "41", name: "Chemba", pname: "Sofala" },
  { _id: "42", name: "Chiringoma", pname: "Sofala" },
  { _id: "43", name: "Chibabava", pname: "Sofala" },
  { _id: "44", name: "Dondo", pname: "Sofala" },
  { _id: "45", name: "Gorongosa", pname: "Sofala" },
  { _id: "46", name: "Machanga", pname: "Sofala" },
  { _id: "47", name: "Maringue", pname: "Sofala" },
  { _id: "48", name: "Marromeu", pname: "Sofala" },
  { _id: "49", name: "Muanza", pname: "Sofala" },
  { _id: "50", name: "Nhamatanda", pname: "Sofala" },
  { _id: "51", name: "Barue", pname: "Manica" },
  { _id: "52", name: "Chimoio", pname: "Manica" },
  { _id: "53", name: "Gondola", pname: "Manica" },
  { _id: "54", name: "Guro", pname: "Manica" },
  { _id: "55", name: "Macate", pname: "Manica" },
  { _id: "56", name: "Machaze", pname: "Manica" },
  { _id: "57", name: "Macossa", pname: "Manica" },
  { _id: "58", name: "Manica", pname: "Manica" },
  { _id: "59", name: "Mussorize", pname: "Manica" },
  { _id: "60", name: "Sussundenga", pname: "Manica" },
  { _id: "61", name: "Tambara", pname: "Manica" },
  { _id: "62", name: "Vanduze", pname: "Manica" },
  { _id: "63", name: "Alto Nolocue", pname: "Zambezia" },
  { _id: "64", name: "Chinde", pname: "Zambezia" },
  { _id: "65", name: "Derre", pname: "Zambezia" },
  { _id: "66", name: "Gile", pname: "Zambezia" },
  { _id: "67", name: "Gurue", pname: "Zambezia" },
  { _id: "68", name: "Ile", pname: "Zambezia" },
  { _id: "69", name: "Inhassunge", pname: "Zambezia" },
  { _id: "70", name: "Luabo", pname: "Zambezia" },
  { _id: "71", name: "Lugela", pname: "Zambezia" },
  { _id: "72", name: "Maganja da Costa", pname: "Zambezia" },
  { _id: "73", name: "Milange", pname: "Zambezia" },
  { _id: "74", name: "Mocuba", pname: "Zambezia" },
  { _id: "75", name: "Mocubela", pname: "Zambezia" },
  { _id: "76", name: "Molumbo", pname: "Zambezia" },
  { _id: "77", name: "Mopeia", pname: "Zambezia" },
  { _id: "78", name: "Morrumbala", pname: "Zambezia" },
  { _id: "79", name: "Mulevala", pname: "Zambezia" },
  { _id: "80", name: "Nhamacurra", pname: "Zambezia" },
  { _id: "81", name: "Namarroi", pname: "Zambezia" },
  { _id: "82", name: "Nicoadala", pname: "Zambezia" },
  { _id: "83", name: "Pebane", pname: "Zambezia" },
  { _id: "84", name: "Quelimane", pname: "Zambezia" },
  { _id: "85", name: "Angonia", pname: "Tete" },
  { _id: "86", name: "Cahora Bassa", pname: "Tete" },
  { _id: "87", name: "Changara", pname: "Tete" },
  { _id: "88", name: "Chifunde", pname: "Tete" },
  { _id: "89", name: "Chiuta", pname: "Tete" },
  { _id: "90", name: "Doa", pname: "Tete" },
  { _id: "91", name: "Macanga", pname: "Tete" },
  { _id: "92", name: "Magoe", pname: "Tete" },
  { _id: "93", name: "Marara", pname: "Tete" },
  { _id: "94", name: "Maravia", pname: "Tete" },
  { _id: "95", name: "Moatize", pname: "Tete" },
  { _id: "96", name: "Mutarara", pname: "Tete" },
  { _id: "97", name: "Tete", pname: "Tete" },
  { _id: "98", name: "Tsangano", pname: "Tete" },
  { _id: "99", name: "Zumbo", pname: "Tete" },
  { _id: "100", name: "Angonche", pname: "Nampula" },
  { _id: "101", name: "Erati", pname: "Nampula" },
  { _id: "102", name: "Ilha de Mocambique", pname: "Nampula" },
  { _id: "103", name: "Lalaua", pname: "Nampula" },
  { _id: "104", name: "Larde", pname: "Nampula" },
  { _id: "105", name: "Liupo", pname: "Nampula" },
  { _id: "106", name: "Malema", pname: "Nampula" },
  { _id: "107", name: "Meconta", pname: "Nampula" },
  { _id: "108", name: "Macubiri", pname: "Nampula" },
  { _id: "109", name: "Memba", pname: "Nampula" },
  { _id: "110", name: "Mogincual", pname: "Nampula" },
  { _id: "111", name: "Mogovolas", pname: "Nampula" },
  { _id: "112", name: "Moma", pname: "Nampula" },
  { _id: "113", name: "Monapo", pname: "Nampula" },
  { _id: "114", name: "Mossuril", pname: "Nampula" },
  { _id: "115", name: "Moecate", pname: "Nampula" },
  { _id: "116", name: "Murrupula", pname: "Nampula" },
  { _id: "117", name: "Nacala a Velha", pname: "Nampula" },
  { _id: "118", name: "Nacala Porto", pname: "Nampula" },
  { _id: "119", name: "Nacaroa", pname: "Nampula" },
  { _id: "120", name: "Nampula", pname: "Nampula" },
  { _id: "121", name: "Rapale", pname: "Nampula" },
  { _id: "122", name: "Ribaue", pname: "Nampula" },
  { _id: "123", name: "Ancuabe", pname: "Cabo Delegado" },
  { _id: "124", name: "Balama", pname: "Cabo Delegado" },
  { _id: "125", name: "Chiure", pname: "Cabo Delegado" },
  { _id: "126", name: "Ibo", pname: "Cabo Delegado" },
  { _id: "127", name: "Macomia", pname: "Cabo Delegado" },
  { _id: "128", name: "Mecufi", pname: "Cabo Delegado" },
  { _id: "129", name: "Meluco", pname: "Cabo Delegado" },
  { _id: "130", name: "Metuge", pname: "Cabo Delegado" },
  { _id: "131", name: "Mocimboa da Praia", pname: "Cabo Delegado" },
  { _id: "132", name: "Montepuez", pname: "Cabo Delegado" },
  { _id: "133", name: "Mueda", pname: "Cabo Delegado" },
  { _id: "134", name: "Miudumbe", pname: "Cabo Delegado" },
  { _id: "135", name: "Namuno", pname: "Cabo Delegado" },
  { _id: "136", name: "Nangade", pname: "Cabo Delegado" },
  { _id: "137", name: "Palma", pname: "Cabo Delegado" },
  { _id: "138", name: "Pemba", pname: "Cabo Delegado" },
  { _id: "139", name: "Quissanga", pname: "Cabo Delegado" },
  { _id: "140", name: "Chimbonila", pname: "Niassa" },
  { _id: "141", name: "Cuamba", pname: "Niassa" },
  { _id: "142", name: "Lago", pname: "Niassa" },
  { _id: "143", name: "Lichinga", pname: "Niassa" },
  { _id: "144", name: "Majune", pname: "Niassa" },
  { _id: "145", name: "Mandimba", pname: "Niassa" },
  { _id: "146", name: "Marrupa", pname: "Niassa" },
  { _id: "147", name: "Maua", pname: "Niassa" },
  { _id: "148", name: "Mavago", pname: "Niassa" },
  { _id: "149", name: "Mecanhelas", pname: "Niassa" },
  { _id: "150", name: "Mecula", pname: "Niassa" },
  { _id: "151", name: "Matarica", pname: "Niassa" },
  { _id: "152", name: "Muembe", pname: "Niassa" },
  { _id: "153", name: "N'gauma", pname: "Niassa" },
  { _id: "154", name: "Nipepe", pname: "Niassa" },
  { _id: "155", name: "sanga", pname: "Niassa" },
];

export default cities;
