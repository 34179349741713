import { InputBox, InputField, Label } from "./FormStyles";
interface Props {
  placeholder: string;
  type: "text" | "search" | "number" | "email" | "password" | "checkbox";
  name: string;
  label: string;
  mandatory?: boolean;
  changed: (e: any) => void;
  defaultValue?: any;
  maximumLength?: number;
  onBlur?: (e: any) => void;
}

const InputText = ({
  maximumLength,
  placeholder,
  label,
  name,
  type,
  changed,
  mandatory,
  defaultValue,
  onBlur
}: Props) => {
  return (
    <InputBox>
      <Label htmlFor={name}>{label}</Label>
      <InputField
        maxLength={maximumLength}
        defaultValue={defaultValue}
        required={mandatory}
        placeholder={placeholder}
        id={name}
        name={name}
        type={type}
        onChange={changed}
        onBlur={onBlur}
      />
    </InputBox>
  );
};

export default InputText;
