import styled from "styled-components";
import { Buttons } from "../Form/FormStyles";
import Paragraph from "../Typography/Paragraph";
import { LightColor } from "../Utils/TextColorUtils";
import Spacer from "./../Utils/Spacer";
import Button from "./Button";
interface Props {
  message: string;
  close: () => void;
  onConfirm: () => void;
  visible: boolean;
  type?: "success" | "alert" | "cancel" | string;
}
const Wrapper = styled.div`
  visibility: ${({ visible }: { visible: boolean }) =>
    visible ? "visible" : "hidden"};
  opacity: ${({ visible }: { visible: boolean }) => (visible ? "1" : "0")};
  z-index: 10001;
  width: 100%;
  height: 100vh;
  font-size: 1.5rem;
  color: var(--grey-dark-1);
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  background-color: hsla(0, 0%, 0%, 0.7);
  transition: all 0.3s;
`;
const Card = styled.div`
  /* visibility: ${({ visible }: { visible: boolean }) =>
    visible ? "visible" : "hidden"}; */
  opacity: ${({ visible }: { visible: boolean }) => (visible ? "1" : "0")};

  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  top: 45%;
  width: 100%;
  position: fixed;
  background-color: var(--white);
  padding: 1.8rem;
  max-width: 45rem;
  min-width: 40rem;
  font-weight: 700;
  box-shadow: var(--shadow-smooth);
  /* height: 70vh; */
  margin: 0 auto;
  border-radius: var(--radius-md);
  transition: all 0.3s;
`;

const Prompt = ({ message, close, visible, onConfirm }: Props) => {
  return (
    <Wrapper visible={visible}>
      <Card visible={visible}>
        <Paragraph>
          <LightColor>{message}</LightColor>
        </Paragraph>
        <Spacer />
        <Buttons>
          <Button
            color="alert"
            action={() => {
              onConfirm();
              close();
            }}
          >
            confirmar
          </Button>
          <Button action={close} color="info">cancelar</Button>
        </Buttons>
      </Card>
    </Wrapper>
  );
};

export default Prompt;
