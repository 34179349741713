import React, { useEffect, useState } from "react";
import InputText from "../components/Form/InputText";
import Button from "../components/Generics/Button";
import Paragraph from "../components/Typography/Paragraph";
import Heading from "../components/Typography/Heading";
import Spacer from "../components/Utils/Spacer";
import Alert from "../components/Generics/Alert";
import { RedColor } from "../components/Utils/TextColorUtils";
import { isAuthenticated, authenticate, updateMyPassword } from "../api";
import Wrapper from "../components/Wrappers/Wrapper";
import { Buttons, LoginForm } from "../components/Form/FormStyles";

const PasswordReset = () => {
  const [formData, setFormData] = useState({} as any);
  const [alertShown, setAlertShown] = useState({
    message: "",
    status: false,
    type: "",
  });
  const [passwordMatch, setPasswordMatch] = useState(true);
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Alterar Senha | BAZARAQUI";
  }, []);
  const handleDataChange = (event: React.FormEvent<HTMLInputElement>) => {
    setPasswordMatch(true);
    setFormData({
      ...formData,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };
  const handleCloseAlert = () => {
    setAlertShown({ ...alertShown, message: "", status: false, type: "" });
  };
  const handleShowAlert = (
    message: string,
    type: "alert" | "cancel" | "success"
  ) => {
    setAlertShown({
      ...alertShown,
      message: message,
      status: true,
      type: type,
    });
  };

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (formData.password !== formData.passwordConfirm)
      return setPasswordMatch(false);

    const res = await updateMyPassword(formData, isAuthenticated().token);
    if (res.status === "fail" || res.status === "error") {
      handleShowAlert(res.message, "alert");
    } else {
      authenticate({ token: res.token, user: res.data.user });
      handleShowAlert("Senha alterada com sucesso", "success");
    }
  };

  return (
    <Wrapper form>
      <Alert
        message={alertShown.message}
        close={handleCloseAlert}
        visible={alertShown.status}
        type={alertShown.type}
      />
      <LoginForm onSubmit={handleSubmit}>
        <Heading size="xl" weight="bold">
          Alterar Senha
        </Heading>
        <Spacer size="xxlg" />
        <InputText
          type="password"
          name="passwordCurrent"
          placeholder="Senha deve ter no minimo 8 caraaceress"
          label="Senha Actual"
          mandatory
          changed={handleDataChange}
        />
        <InputText
          type="password"
          name="password"
          placeholder="Senha deve ter no minimo 8 caraaceress"
          label="Nova senha"
          mandatory
          changed={handleDataChange}
        />
        <InputText
          type="password"
          name="passwordConfirm"
          placeholder="Repetir a senha anterior"
          label="Repetir a senha"
          mandatory
          changed={handleDataChange}
        />
        {!passwordMatch ? (
          <Paragraph size="sm" weight="thin">
            <RedColor>Senhas devem ser iguais!</RedColor>
          </Paragraph>
        ) : null}

        <Spacer size="xlg" />
        <Buttons>
          <Button color="alert">Alterar senha</Button>
        </Buttons>
        <Spacer size="md" />
      </LoginForm>
    </Wrapper>
  );
};

export default PasswordReset;
