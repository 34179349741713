import styled from "styled-components";

export const YellowColor = styled.span`
    color: var(--yellow);
`;
export const LightColor = styled.span`
  color: var(--grey-light-2);

`
export const RedColor = styled.span`
    color: var(--red);
`;

export const DarkColor = styled.span`
  color: var(--grey-dark);
`;

export const Dark1Color = styled.span`
  color: var(--grey-dark-1);
`;