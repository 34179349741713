import axios from "axios";
import { API } from "../config";
// const API = "localhost:5000/api/v1";
export const getCategories = async () => {
  try {
    const res = await axios({
      method: "GET",
      url: `${API}/categories`,
    });

    return res.data.data.data;
  } catch (err) {
    console.log(err.response.data);
    return err.response.data;
  }
};
export const getMarkets = async (id = "") => {
  try {
    const res = await axios({
      method: "GET",
      url: `${API}/markets${id}?active=true`,
    });

    return res.data.data.data;
  } catch (err) {
    console.log(err.response.data);
    return err.response.data;
  }
};
export const findProducts = async (query, category) => {
  try {
    const res = await axios({
      method: "GET",
      url: `${API}/products/search?search=${query}`,
    });
    return res.data.data.data;
  } catch (error) {
    console.log(error.response.data);
    return error.response.data;
  }
};
export const getGroceriesOnMarket = async (id) => {
  try {
    const res = await axios({
      method: "GET",
      url: `${API}/groceries?market=${id}`,
    });

    return res.data.data.data;
  } catch (err) {
    console.log(err.response.data);
    return err.response.data;
  }
};
export const getGroceries = async (query = "") => {
  try {
    const res = await axios({
      method: "GET",
      url: `${API}/groceries?active=true&${query}`,
    });
    return res.data.data.data;
  } catch (err) {
    console.log(err.response.data);
    return err.response.data;
  }
};

export const getGrocery = async (slug) => {
  try {
    const res = await axios({
      method: "GET",
      url: `${API}/groceries/slug/${slug}`,
    });

    return res.data.data;
  } catch (err) {
    console.log(err.response.data);
    return err.response.data;
  }
};

export const getProduct = async (slug) => {
  try {
    const res = await axios({
      method: "GET",
      url: `${API}/products/slug/${slug}`,
    });

    return res.data.data;
  } catch (err) {
    console.log(err.response.data);
    return err.response.data;
  }
};

export const createGrocery = async (grocery, token) => {
  try {
    const res = await axios({
      method: "POST",
      url: `${API}/groceries/`,
      data: grocery,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

//auth
export const signup = async (user) => {
  try {
    const res = await axios({
      method: "POST",
      url: `${API}/users/signup`,
      data: user,
    });

    return res.data;
  } catch (err) {
    return err.response.data;
  }
};
export const createAdmin = async (user, token) => {
  try {
    const res = await axios({
      method: "POST",
      url: `${API}/users/createAdmin`,
      data: user,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data.data.data;
  } catch (err) {
    console.log(err.response);
    return err.response.data;
  }
};

export const login = async (user) => {
  try {
    const res = await axios({
      method: "POST",
      url: `${API}/users/login`,
      data: user,
    });

    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const logout = async (callback) => {
  if (typeof window !== "undefined") {
    if (callback) callback();

    try {
      const res = await axios({
        method: "GET",
        url: `${API}/users/logout`,
      });
      window.localStorage.removeItem("bhazaraaquidata");
      return res.data;
    } catch (err) {
      return err.response.data;
    }
  }
};

export const forgotPassword = async (data) => {
  try {
    const res = await axios({
      method: "POST",
      url: `${API}/users/forgotPassword`,
      data,
    });

    return res.data;
  } catch (err) {
    console.log(err.response.data);
    return err.response.data;
  }
};
export const resetPassword = async (url, data) => {
  try {
    const res = await axios({
      method: "PATCH",
      url,
      data,
    });
    return res.data;
  } catch (err) {
    console.log(err.response.data);
    return err.response.data;
  }
};

export const authenticate = (data, callback) => {
  if (typeof window !== "undefined") {
    localStorage.setItem("bhazaraaquidata", JSON.stringify(data));

    if (callback) callback();
  }
};
export const isAuthenticated = () => {
  if (typeof window == "undefined") {
    return false;
  }

  if (localStorage.getItem("bhazaraaquidata")) {
    return JSON.parse(localStorage.getItem("bhazaraaquidata"));
  } else {
    return false;
  }
};
//end auth
export const updateMe = async (user, token) => {
  try {
    const res = await axios({
      method: "PATCH",
      url: `${API}/users/updateMe`,
      data: user,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return res.data.data.user;
  } catch (err) {
    return err.response.data;
  }
};
export const getMe = async (token) => {
  try {
    const res = await axios({
      method: "GET",
      url: `${API}/users/me`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return res.data.data.data;
  } catch (err) {
    return err.response.data;
  }
};
//
export const addFavoriteGrocery = async (data, token) => {
  try {
    const res = await axios({
      method: "POST",
      url: `${API}/users/favoriteGroceries/`,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return res.data.data;
  } catch (err) {
    return err.response.data;
  }
};
export const removeFavoriteGrocery = async (data, token) => {
  try {
    const res = await axios({
      method: "DELETE",
      url: `${API}/users/favoriteGroceries/`,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return res.data.data;
  } catch (err) {
    return err.response.data;
  }
};
export const getFavoriteGroceries = async (data, token) => {
  //returns ids only
  try {
    const res = await axios({
      method: "GET",
      url: `${API}/users/favoriteGroceries/`,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return res.data.data;
  } catch (err) {
    return err.response.data;
  }
};
export const getMyFavoriteGroceries = async (user, token) => {
  //returns the groceries
  try {
    const res = await axios({
      method: "GET",
      url: `${API}/groceries/favoriteGroceries/${user}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return res.data.data;
  } catch (err) {
    return err.response.data;
  }
};
//favorites

export const updateMyPassword = async (data, token) => {
  try {
    const res = await axios({
      method: "PATCH",
      url: `${API}/users/updateMyPassword`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });

    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

//ADMIN
export const getUserStatistics = async (token) => {
  try {
    const res = await axios({
      method: "GET",
      url: `${API}/users/user-stats`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return res.data.data;
  } catch (err) {
    return err.response.data;
  }
};

export const getGroceryStatistics = async (token) => {
  try {
    const res = await axios({
      method: "GET",
      url: `${API}/groceries/grocery-stats`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return res.data.data;
  } catch (err) {
    return err.response.data;
  }
};

export const getProducts = async (token) => {
  try {
    const res = await axios({
      method: "GET",
      url: `${API}/products`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return res.data.data.data;
  } catch (err) {
    return err.response.data;
  }
};

export const getUsers = async (token, query = "/") => {
  try {
    const res = await axios({
      method: "GET",
      url: `${API}/users${query}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return res.data.data.data;
  } catch (err) {
    return err.response.data;
  }
};

export const blockGrocery = async (id, token) => {
  try {
    const res = await axios({
      method: "PATCH",
      url: `${API}/groceries/blockGrocery/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return res.data.data.data;
  } catch (err) {
    return err.response.data;
  }
};

export const unblockGrocery = async (id, token) => {
  try {
    const res = await axios({
      method: "PATCH",
      url: `${API}/groceries/unblockGrocery/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return res.data.data.data;
  } catch (err) {
    return err.response.data;
  }
};

export const blockMarket = async (id, token) => {
  try {
    const res = await axios({
      method: "PATCH",
      url: `${API}/markets/blockMarket/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return res.data.data;
  } catch (err) {
    return err.response.data;
  }
};

export const unblockMarket = async (id, token) => {
  try {
    const res = await axios({
      method: "PATCH",
      url: `${API}/markets/unblockMarket/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return res.data.data;
  } catch (err) {
    return err.response.data;
  }
};

export const createMarket = async (data, token) => {
  try {
    const res = await axios({
      method: "POST",
      url: `${API}/markets`,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return res.data.data;
  } catch (err) {
    return err.response.data;
  }
};

export const updateMarket = async (id, data, token) => {
  try {
    const res = await axios({
      method: "PATCH",
      url: `${API}/markets/${id}`,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return res.data.data;
  } catch (err) {
    return err.response.data;
  }
};

//SELLER
export const createProduct = async (data, token) => {
  try {
    const res = await axios({
      method: "POST",
      url: `${API}/products`,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return res.data.data;
  } catch (err) {
    return err.response.data;
  }
};

export const deleteProduct = async (id, token) => {
  try {
    const res = await axios({
      method: "DELETE",
      url: `${API}/products/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return res.data.data;
  } catch (err) {
    return err.response.data;
  }
};

export const updateProduct = async (data, id, token) => {
  try {
    const res = await axios({
      method: "PATCH",
      url: `${API}/products/${id}`,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return res.data.data.data;
  } catch (err) {
    return err.response.data;
  }
};

export const getMyGrocery = async (manager) => {
  try {
    const res = await axios({
      method: "GET",
      url: `${API}/groceries/my-grocery/${manager}`,
    });

    return res.data.data;
  } catch (err) {
    console.log(err.response.data);
    return err.response.data;
  }
};

export const updateMyGrocery = async (id, data, token) => {
  try {
    const res = await axios({
      method: "PATCH",
      url: `${API}/groceries/${id}`,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return res.data.data.data;
  } catch (err) {
    return err.response.data;
  }
};
