import { useEffect } from "react";
import styled from "styled-components";
import Heading from "../components/Typography/Heading";
import Paragraph from "../components/Typography/Paragraph";
import Container from "../components/Wrappers/Container";
import Wrapper from "../components/Wrappers/Wrapper";

const Card = styled.div`
  padding: 1.8rem;
  margin-top: 4rem;
  background-color: var(--white);
  box-shadow: var(--shadow-smooth);
  text-align: center;
  padding-bottom: 4rem;
`;

const PrivacyPolicies = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `Politicas de privacidade | Bazaraqui`;
    // eslint-disable-next-line
  }, []);
  return (
    <Wrapper>
      <Container>
        <Card>
          <Heading weight="bold">Privacidade</Heading>
          <Paragraph weight="normal">
            <span className="info__sub-title">Política de dados</span>
            Esta política descreve as informações que processamos para oferecer
            suporte ao BazarAqui.
            <span className="info__title">
              Que tipo de informação coletamos?
            </span>
            Para fornecer os produtos do BazarAqui, precisamos processar
            informações sobre você ou sua banca. Você pode aprender como acessar
            e excluir as informações que coletamos editando o teu perfil do
            BazarAqui.
            <span className="info__title">
              Informações e conteúdo que você fornece.
            </span>
            Coletamos o conteúdo, as comunicações e outras informações que você
            fornece ao usar nossas funcionalidades, inclusive quando você marca
            uma banca como favorita ou faz uma compra. Isso pode incluir
            informações sobre você ou sobre o conteúdo que você fornece.
            <span className="info__sub-title">Seu uso</span>
            <span className="info__sub-par">
              Coletamos informações sobre como você usa nossas funcionalidades,
              como os tipos de conteúdo que você responde ou se envolve; os
              recursos que você usa; as ações que você toma; e as bancas ou
              mercados com as quais você interage. Também coletamos informações
              sobre quais bancas você é gosta, quantas compras fez e os produtos
              dessa compras.
            </span>
            <span className="info__title">Como usamos essas informações?</span>
            Usamos as informações que temos (sujeitas às escolhas que você faz),
            conforme descrito abaixo, e para fornecer e oferecer suporte ao
            BazarAqui. Aqui está como:
            <span className="info__sub-title">Informações sobre o ti</span>
            <span className="info__sub-par">
              Usamos as informações que temos para entregar nossos produtos,
              inclusive para personalizar recursos e conteúdo que aparecem para
              ti. Para organizar as bancas de acordo com a sua perferencia.
            </span>
            <span className="info__sub-title">
              Promover a segurança, integridade e segurança.
            </span>
            <span className="info__sub-par">
              Usamos as informações que temos para verificar contas e
              atividades, combater conduta prejudicial, detectar e impedir spam
              e outras experiências ruins, manter a integridade de nossos
              produtos e promover a segurança dentro e fora dos produtos do
              BazarAqui. Por exemplo, usamos dados que temos para investigar
              atividades suspeitas ou violações de nossos termos ou políticas,
              ou para detectar quando alguém precisa de ajuda.
            </span>
            <span className="info__sub-title">Comunicar com você.</span>
            <span className="info__sub-par">
              Usamos as informações que temos para nos comunicar com você sobre
              nossos produtos, sua actividade no BazarAqui e informá-lo sobre
              nossas políticas de privacidade e termos. Também usamos suas
              informações para responder quando você entrar em contato connosco.
            </span>
          </Paragraph>
        </Card>
      </Container>
    </Wrapper>
  );
};

export default PrivacyPolicies;
