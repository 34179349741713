import styled from "styled-components";
import Link from "../Generics/Link";
import IconWrapper from "../Wrappers/IconWrapper";
import { GrFormClose } from "react-icons/gr";
import { logout } from "../../api";
import { useHistory } from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";

interface Props {
  visible: boolean;
  close: () => void;
}
const Wrapper = styled.div`
  visibility: ${({ visible }: { visible: boolean }) =>
    visible ? "visible" : "hidden"};
  opacity: ${({ visible }: { visible: boolean }) => (visible ? "1" : "0")};
  z-index: 1001;
  width: 100%;
  height: 100vh;
  font-size: 1.5rem;
  color: var(--grey-dark-1);
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  background-color: hsla(0, 0%, 0%, 0.7);
  transition: all 0.3s;
`;
const CloseButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
const Card = styled.div`
  transform: ${({ visible }: { visible: boolean }) =>
    visible ? "translateY(0)" : "translateY(-20rem)"};
  top: 15rem;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 1.6rem;
  background-color: var(--white);
  padding: 1.8rem;
  max-width: 45rem;
  min-width: 40rem;
  transition: all 0.3s;
  height: 40rem;
  margin: 0 auto;
  border-radius: var(--radius-md);
`;

const Lnk = styled.div`
  text-decoration: none;
  text-transform: capitalize;
  cursor: pointer;
  padding: 1px 3px;
  /* height: var(--input-height); */
  display: inline-block;
  border-bottom: 2px solid transparent;
  font: inherit;
  font-weight: 600;
  color: var(--yellow);
  text-align: center;
  transition: all 0.3s;
  &:hover {
    border-color: var(--yellow);
  }
`;
const ProductPopup = ({ visible, close }: Props) => {
  const history = useHistory();
  const { uiUpdate } = useContext(AppContext);
  const [updateUi, setUpdateUi] = uiUpdate;
  return (
    <Wrapper
      visible={visible}
      onClick={(event) => {
        if (event.target === event.currentTarget) close();
      }}
    >
      <Card visible={visible}>
        <CloseButton onClick={close}>
          <IconWrapper size="lg">
            <GrFormClose />
          </IconWrapper>
        </CloseButton>
        <Link color="alert" to="/user-update/data">
          Alterar Dados
        </Link>
        <Link color="alert" to="/user-update/photo">
          Alterar foto
        </Link>
        <Link color="alert" to="/update-password">
          Alterar Senha
        </Link>
        <Link color="alert" to="/about-us">
          Sobre
        </Link>
        <Link color="alert" to="/faqs">
          Perguntas frequentes
        </Link>
        <Link color="alert" to="/privacy-policies">
          Politicas de privacidade
        </Link>
        <Link color="alert" to="/terms-and-conditions">
          Termos e condições
        </Link>
        <Lnk
          onClick={() => {
            logout().then(() => {
              setUpdateUi(!updateUi);
              history.push("/");
            });
          }}
        >
          sair
        </Lnk>
      </Card>
    </Wrapper>
  );
};

export default ProductPopup;
