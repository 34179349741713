import { InputBox, Label, TArea } from "./FormStyles";
interface Props {
  placeholder: string;
  name: string;
  label: string;
  mandatory?: boolean;
  changed: (e: any) => void;
  defaultValue?: any;
  onBlur?: (e: any) => void;
}

const InputText = ({
  placeholder,
  label,
  name,
  changed,
  mandatory,
  onBlur,
}: Props) => {
  return (
    <InputBox>
      <Label htmlFor={name}>{label}</Label>
      <TArea
        id={name}
        required={mandatory}
        placeholder={placeholder}
        name={name}
        onChange={changed}
        onBlur={onBlur}
      ></TArea>
    </InputBox>
  );
};

export default InputText;
