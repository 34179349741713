import React from "react";
import styled from "styled-components";
interface Props {
  action?: () => void;
  children: React.ReactNode;
  color?: "success" | "cancel" | "info" | "alert";
  w0?: boolean;
  size?: "sm" | "lg" | "full";
  name?: string;
}
const typeToColor = (color?: "success" | "cancel" | "info" | "alert") => {
  switch (color) {
    case "success":
      return "--green";
    case "cancel":
      return "--red";
    case "info":
      return "--blue";
    case "alert":
      return "--yellow";
    default:
      return "--grey-light-1";
  }
};
const Btn = styled.button`
  width: 25rem;
  text-transform: uppercase;
  cursor: pointer;
  border: 3px solid transparent;
  border-radius: var(--radius-md);
  height: var(--input-height);
  font-weight: 600;
  display: inline-block;
  font-size: 1.5rem;
  color: var(--white);
  background-color: var(${({ color }: Props) => typeToColor(color)});
  text-align: center;
  padding: ${({ w0 }: Props) => (w0 ? "unset" : "0 2rem")};
  max-width: ${({ w0 }: Props) => (w0 ? "var(--input-height)" : "initial")};
  transition: all 0.3s;
  @media only screen and (max-width: 600px) {
    width: ${({ size, w0 }: Props) =>
      size === "full" ? "100%" : w0 ? "5rem" : "unset"};
  }
  &:hover {
    background-color: var(${({ color }: Props) => typeToColor(color)}-active);
  }
  &:focus {
    border-color: var(${({ color }: Props) => typeToColor(color)});
  }
`;

const Button = ({ size, action, children, color, w0, name }: Props) => {
  return (
    <Btn onClick={action} color={color} w0={w0} size={size} name={name}>
      {children}
    </Btn>
  );
};

export default Button;
