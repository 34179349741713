import styled from "styled-components";

interface Props {
  size?: "xsm" | "sm" | "md" | "lg" | "xlg" | "xxlg";
}
const Space = styled.div`
  display: block;
  width: 100%;
  height: ${({ size }: Props) => {
    switch (size) {
      case "xsm":
        return "2px";
      case "sm":
        return "10px";
      case "md":
        return "18px";
      case "lg":
        return "25px";
      case "xlg":
        return "30px";
      case "xxlg":
        return "35px";
      default:
        return "1.5rem";
    }
  }};
`;
const Spacer = ({ size }: Props) => {
  return <Space size={size} />;
};

export default Spacer;
