import styled from "styled-components";

interface Props {
  id: string;
  name: string;
  value: string;
  label: string;
  checked?: boolean;
  changed: (event?: any) => void;
}
const Category = styled.label`
  text-transform: capitalize;
  cursor: pointer;
  font-weight: 200;
  padding: 0.8rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--grey-light);
  border-radius: 40px;
  color: var(--grey-light-2);
  margin-right: 1rem;
  margin-bottom: 1rem;
`;
const Radio = styled.input`
  display: none;
  &:checked + label {
    border-color: var(--yellow);
  }
`;
const RadioButton = ({ id, name, value, label, changed, checked }: Props) => {
  return (
    <>
      <Radio
        type="radio"
        id={id}
        name={name}
        value={value}
        onChange={changed}
        checked={checked}
      />

      <Category htmlFor={id}>{label}</Category>
    </>
  );
};

export default RadioButton;
