import { useEffect } from "react";
import { GrFormClose } from "react-icons/gr";
import styled from "styled-components";
import Paragraph from "../Typography/Paragraph";
import IconWrapper from "../Wrappers/IconWrapper";
interface Props {
  message: string;
  close: () => void;
  visible: boolean;
  type: "success" | "alert" | "cancel" | string;
}
const typeToColor = (color: any) => {
  switch (color) {
    case "success":
      return "--green";
    case "cancel":
      return "--red";
    case "alert":
      return "--yellow";
    default:
      return "--grey-light-1";
  }
};
const Card = styled.div`
  visibility: ${({ visible }: { visible: boolean }) =>
    visible ? "visible" : "hidden"};
  opacity: ${({ visible }: { visible: boolean }) => (visible ? "1" : "0")};
  transform: ${({ visible }: { visible: boolean }) =>
    !visible ? "translateY(-10rem)" : "translaateY(0)"};
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  top: 10rem;
  width: 100%;
  position: fixed;
  background-color: var(--white);
  padding: 1.5rem;
  max-width: 45rem;
  min-width: 40rem;
  font-weight: 700;
  box-shadow: var(--shadow-smooth);
  /* height: 70vh; */
  margin: 0 auto;
  border-radius: var(--radius-md);
  border-bottom: 5px solid var(${({ color }) => typeToColor(color)});
  transition: all 0.3s;
`;
const CloseButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
const CloseButton = styled.div`
  cursor: pointer;
  &:hover,
  &:active {
    color: var(--red);
  }
`;
const Alert = ({ message, close, visible, type }: Props) => {
  useEffect(() => {
    let timer = setTimeout(() => {
      close();
    }, 5000);
    return () => clearTimeout(timer);
    //eslint-disable-next-line
  }, [visible]);
  return (
    <Card visible={visible} color={type}>
      <CloseButtonWrapper>
        <CloseButton onClick={close}>
          <IconWrapper size="lg">
            <GrFormClose />
          </IconWrapper>
        </CloseButton>
      </CloseButtonWrapper>
      <Paragraph>{message}</Paragraph>
    </Card>
  );
};

export default Alert;
