import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import InputText from "../components/Form/InputText";
import Button from "../components/Generics/Button";
import Heading from "../components/Typography/Heading";
import Paragraph from "../components/Typography/Paragraph";
import prvncss from "./../utils/provinces";
import dstrt from "./../utils/cities";
import Spacer from "../components/Utils/Spacer";
import { signup, authenticate, createGrocery } from "./../api";
import Loader from "./../components/Animations/Loader";
import Alert from "../components/Generics/Alert";
import { getMarkets } from "../api";
import {
  LoginForm,
  Buttons,
  Label,
  RadioGroup,
  CheckBoxes,
} from "../components/Form/FormStyles";
import RSelect from "../components/Form/RSelect";
import RadioButton from "../components/Form/RadioButton";
import CheckBox from "../components/Form/CheckBox";
import { YellowColor } from "../components/Utils/TextColorUtils";
import { AppContext } from "../context/AppContext";
import Wrapper from "../components/Wrappers/Wrapper";

const payments = [
  "ABSA",
  "M-Pesa",
  "E-Mola",
  "M-Kesh",
  "Ponto24",
  "BancoUnico",
  "FNB",
  "MozaBanco",
  "StandardBank",
  "BCI",
  "MillenniumBim",
  "BancABC",
];
const Signup = () => {
  const history = useHistory();
  const [markets, setMarkets] = useState([] as IMarket[]);
  const [loading, setLoading] = useState(false);
  const [alertShown, setAlertShown] = useState({
    message: "",
    status: false,
    type: "",
  });
  const { uiUpdate } = useContext(AppContext);
  const [updateUi, setUpdatUi] = uiUpdate;
  const [districts, setDistricts] = useState([] as any[]);
  const [values, setValues] = useState({
    name: "",
    phoneNumber: "",
    province: "",
    district: "",
    address: "",
    password: "",
    passwordConfirm: "",

    groceryName: "",
    market: "",
    paymentMethods: [],
  });
  const [role, setRole] = useState("buyer"); //use this variable to control user type and change it's value accordingly
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Criar Conta | BAZARAQUI";
  }, []);
  useEffect(() => {
    const init = async () => {
      const res = await getMarkets();
      setMarkets(res);
    };
    init();
  }, []);
  const {
    name,
    phoneNumber,
    province,
    district,
    address,
    password,
    passwordConfirm,
    groceryName,
    market,
    paymentMethods,
  } = values;

  const handleCloseAlert = () => {
    setAlertShown({ ...alertShown, message: "", status: false, type: "" });
  };
  const handleShowAlert = (
    message: string,
    type: "alert" | "cancel" | "success"
  ) => {
    setAlertShown({
      ...alertShown,
      message: message,
      status: true,
      type: "type",
    });
  };

  const handleRoleChange = (event: React.FormEvent<HTMLInputElement>) => {
    setRole(event.currentTarget.value);
  };

  const handleChange = (input: any) => (event: any) => {
    setValues({
      ...values,
      [input]: event.target.value,
    });
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    if (!phoneNumber.match(/^(84|85|86|87|83|82)\d{7}$/))
      return handleShowAlert(
        "ERRO: o número de celular tem de ser Moçambicano",
        "cancel"
      );
    const res = await signup({
      name,
      phoneNumber,
      province,
      district,
      address,
      password,
      passwordConfirm,
    });
    setLoading(false);
    if (res.status === "error" || res.status === "fail") {
      if (res.message.includes("E11000"))
        return handleShowAlert(
          "O número do celular inserido já foi usado!",
          "cancel"
        );
      handleShowAlert(res.message, "cancel");
    } else {
      handleShowAlert("conta criada com sucesso", "success");

      if (role === "seller") {
        await createGrocery(
          {
            name: groceryName,
            manager: res.data.user.id,
            market,
            paymentMethods,
            contact: res.data.user.phoneNumber,
          },
          res.token
        );
        res.data.user.role = "seller";
      }
      authenticate({ token: res.token, user: res.data.user });
      setUpdatUi(!updateUi);
      history.push("/");
    }
  };
  const handleProvince = (option: any) => {
    setDistricts(
      dstrt.filter((el) => {
        return el.pname === option.name;
      })
    );
    setValues({ ...values, province: option.name });
  };
  const paymentMethodsHandler = (event: any) => {
    let sellerMethods: any = paymentMethods;
    const method = event.target.value;

    const addMethod = () => {
      sellerMethods.push(method);
      //removing duplicates
      sellerMethods = sellerMethods.filter(
        (a: string, b: string) => sellerMethods.indexOf(a) === b
      );
    };

    const removeMethod = () => {
      sellerMethods = sellerMethods.filter((el: any) => el !== method);
    };

    event.target.checked ? addMethod() : removeMethod();

    setValues({ ...values, paymentMethods: sellerMethods });
  };
  const options: any[] | undefined = markets.map((el, i) => {
    return { label: el.name, value: el.id };
  });

  return (
    <Wrapper form>
      <Alert
        message={alertShown.message}
        close={handleCloseAlert}
        visible={alertShown.status}
        type={alertShown.type}
      />
      {loading ? <Loader /> : null}

      <LoginForm onSubmit={handleSubmit}>
        <Heading size="xl" weight="bold">
          Criar Conta
        </Heading>
        <Spacer size="xxlg" />
        <Label>Tipo de conta</Label>
        <RadioGroup>
          <RadioButton
            id="buyer"
            name="role"
            value="buyer"
            changed={handleRoleChange}
            label="cliente"
          />
          <RadioButton
            id="seller"
            name="role"
            value="seller"
            changed={handleRoleChange}
            label="vendedor"
          />
        </RadioGroup>
        <InputText
          type="text"
          name="name"
          placeholder="Insira o seu nome completo"
          label="nome"
          changed={handleChange("name")}
          mandatory
        />
        <RSelect
          label="província"
          id="provincia"
          values={prvncss.map((el) => el.name)}
          changed={handleProvince}
          defaultSelected="Seleccione uma província"
        />
        <RSelect
          id="provincia"
          label="município"
          values={districts.map((el) => el.name)}
          defaultSelected="Seleccione um município "
          changed={(event: any) =>
            setValues({ ...values, district: event.name })
          }
        />

        <InputText
          type="text"
          name="address"
          placeholder="Insira o seu endereço"
          label="endereço"
          changed={handleChange("address")}
          mandatory
        />

        <InputText
          type="number"
          name="phone"
          placeholder="Insira o seu número de celular"
          label="número de celular"
          changed={handleChange("phoneNumber")}
          mandatory
        />
        <InputText
          type="password"
          name="password"
          placeholder="Insira a sua senha"
          label="senha"
          changed={handleChange("password")}
          mandatory
        />
        <InputText
          type="password"
          name="passwordCoonfirm"
          placeholder="Insira novamente a sua senha"
          label="Repetir a senha"
          changed={handleChange("passwordConfirm")}
          mandatory
        />
        {role === "seller" ? (
          <>
            <Label>Mercado</Label>
            <Select
              options={options}
              onChange={(event: any) =>
                setValues({ ...values, market: event.value })
              }
              defaultValue={{ label: "Seleccione um mercado" }}
            />

            <InputText
              type="text"
              name="groceryName"
              placeholder="Insira o nome da sua banca"
              label="Banca"
              changed={handleChange("groceryName")}
              mandatory
            />
            <Paragraph size="lg" weight="normal">
              Métodos de pagamento
            </Paragraph>
            <CheckBoxes>
              {payments.map((payment, i) => (
                <CheckBox
                  name="paymentMethodType"
                  id={payment}
                  value={payment}
                  label={payment}
                  changed={paymentMethodsHandler}
                  key={i}
                />
              ))}
            </CheckBoxes>
          </>
        ) : (
          <></>
        )}
        <Spacer />
        <Paragraph size="sm" weight="thin">
          Ao criar uma conta ou iniciar sessão, você concorda com os{" "}
          <Link to="/terms-and-conditions">termos de uso</Link> e{" "}
          <Link to="/privacy-policies">política de privacidade</Link> do
          BazarAqui!
        </Paragraph>
        <Spacer size="xlg" />
        <Buttons>
          <Button color="alert">Criar Conta</Button>
          <Button action={() => history.push("/login")}>
            <YellowColor>Iniciar Sessão</YellowColor>
          </Button>
        </Buttons>
        <Spacer size="md" />
      </LoginForm>
    </Wrapper>
  );
};

export default Signup;
