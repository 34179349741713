import React, { useState, useEffect } from "react";
import Button from "../components/Generics/Button";
import styled from "styled-components";
import ProductCard from "../components/Layout/ProductCard";
import Heading from "../components/Typography/Heading";
import Paragraph from "../components/Typography/Paragraph";
import Container from "../components/Wrappers/Container";
import Wrapper from "../components/Wrappers/Wrapper";
import {
  getGrocery,
  getCategories,
  getFavoriteGroceries,
  addFavoriteGrocery,
  removeFavoriteGrocery,
  isAuthenticated,
} from "../api";
import Loader from "./../components/Animations/Loader";
import IconWrapper from "./../components/Wrappers/IconWrapper";
import { FiPhone } from "react-icons/fi";
import { FaWhatsapp, FaHeart, FaRegHeart } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import ProductPopup from "../components/Layout/ProductPopup";
import RadioButton from "../components/Form/RadioButton";
import { RadioGroup } from "../components/Form/FormStyles";
import Alert from "../components/Generics/Alert";

interface IParams {
  slug: string;
}
const Grid = styled.div`
  width: 100%;
  padding: 2rem 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  align-items: center;
  gap: 2rem;
`;

const Contacts = styled.div`
  display: flex;
  align-items: center;
`;
const YellowCollor = styled.span`
  color: var(--yellow);
`;

const Grocery = () => {
  const [grocery, setGrocery] = useState({} as IGrocery);
  const [categories, setCategories] = useState([] as ICategory[]);
  const [productsHelper, setProductsHelper] = useState([] as IProduct[]);
  const [selectedProduct, setSelectedProduct] = useState({} as IProduct);
  const [popupShown, setPopupShown] = useState(false);
  const [favorite, setFavorite] = useState(false);
  const [loading, setLoading] = useState(false);
  const { slug } = useParams<IParams>();
  const [alertShown, setAlertShown] = useState({
    message: "",
    status: false,
    type: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Banca | BAZARAQUI";
  }, []);
  useEffect(() => {
    const init = async () => {
      setLoading(true);
      const res = await getGrocery(slug);
      setGrocery(res);
      setProductsHelper(res.products);
      const cats = await getCategories();
      setCategories(cats);
      //handling favorites
      const { user, token } = isAuthenticated();
      if (user) {
        const fav = await getFavoriteGroceries({ user: user.id }, token);
        let isFavorite = false;
        fav.forEach((el: string) => {
          if (el.toString() === res._id.toString()) isFavorite = true;
        });
        setFavorite(isFavorite);
      }
      setLoading(false);
    };
    init();
  }, [slug]);
  const handleFilter = (event: React.FormEvent) => {
    event.preventDefault();
  };
  const handlePopup = (product: IProduct) => {
    setSelectedProduct(product);
    setPopupShown(true);
  };
  const handleClosePopup = () => {
    setPopupShown(false);
  };
  const handleFavoriteChange = () => {
    const { user, token } = isAuthenticated();

    const addToMyFavorites = async () => {
      await addFavoriteGrocery(
        {
          user: user._id,
          grocery: grocery._id,
        },
        token
      );
    };

    const removeFromMyFavorites = async () => {
      await removeFavoriteGrocery(
        {
          user: user._id,
          grocery: grocery._id,
        },
        token
      );
    };

    if (isAuthenticated()) {
      favorite !== true ? addToMyFavorites() : removeFromMyFavorites();
      setFavorite(!favorite);
    } else {
      handleShowAlert(
        <>
          Você deve <Link to="/signup">criar uma conta</Link> ou{" "}
          <Link to="/login">iniciar sessão</Link> para usar esta funcionalidade!
        </>,
        "alert"
      );
    }
  };
  const filterHandler = (event: any) => {
    const category = event.target.id;
    let filteredProducts = productsHelper;

    filteredProducts = filteredProducts.filter(
      (el: IProduct) => el.category === category
    );

    category !== "All"
      ? setGrocery({ ...grocery, products: filteredProducts })
      : setGrocery({ ...grocery, products: productsHelper });
  };

  const handleCloseAlert = () => {
    setAlertShown({ ...alertShown, message: "", status: false, type: "" });
  };
  const handleShowAlert = (
    message: any,
    type: "alert" | "cancel" | "success"
  ) => {
    setAlertShown({
      ...alertShown,
      message: message,
      status: true,
      type: "type",
    });
  };
  return (
    <>
      <ProductPopup
        grocery={grocery.name}
        productName={selectedProduct.name}
        marketName={grocery.market && grocery.market.name}
        image={selectedProduct.image}
        priceVariation={selectedProduct.priceVariations}
        contact={grocery.contact}
        paymentMethods={grocery.paymentMethods}
        visible={popupShown}
        close={handleClosePopup}
      />
      <Wrapper>
        <Alert
          message={alertShown.message}
          close={handleCloseAlert}
          visible={alertShown.status}
          type={alertShown.type}
        />
        <Container>
          <Contacts>
            <IconWrapper>
              <FiPhone />
            </IconWrapper>
            &nbsp;&nbsp;contacto /&nbsp;
            <IconWrapper>
              <FaWhatsapp />
            </IconWrapper>
            &nbsp;&nbsp; whatsapp
          </Contacts>
          <Paragraph size="lg">
            <YellowCollor>+258 {grocery.contact}</YellowCollor>
          </Paragraph>
          <Button w0 color="alert" action={handleFavoriteChange}>
            <IconWrapper>{favorite ? <FaHeart /> : <FaRegHeart />}</IconWrapper>
          </Button>
          <Heading size="xl" weight="bolder">
            {grocery.name}
          </Heading>
          <Paragraph weight="bold">Filtrar categorias</Paragraph>

          {/* <Paragraph weight="thin">{grocery.about}</Paragraph> */}
          <form onSubmit={handleFilter}>
            <RadioGroup>
              <RadioButton
                id="All"
                name="category"
                value="Todas"
                label="Todas"
                changed={filterHandler}
              />
              {categories.map((category, i) => (
                <RadioButton
                  label={category.name}
                  key={i}
                  id={category._id}
                  name="category"
                  value={category.name}
                  changed={filterHandler}
                />
              ))}
            </RadioGroup>
          </form>

          <Heading size="lg" weight="bolder">
            Produtos
          </Heading>
          {grocery.products &&
            (grocery.products.length ? (
              <Grid>
                {grocery.products.map((product, i) => (
                  <ProductCard
                    key={i}
                    page={`/product/${product.slug}`}
                    name={product.name}
                    image={product.image}
                    price={product.priceVariations}
                    action={() => {
                      handlePopup(product);
                    }}
                  />
                ))}
              </Grid>
            ) : (
              <Paragraph>Nenhum Produto</Paragraph>
            ))}
          {loading ? <Loader /> : null}
        </Container>
      </Wrapper>
    </>
  );
};

export default Grocery;
