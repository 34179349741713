import { keyframes } from "styled-components";

const swaper = keyframes`
    from{
        transform: translateX(-95vw);
        filter: blur(15px);
    }
    to{
        transform: translateX(0);
        filter: blur(0);
    }
`;

export default swaper;
