import React from "react";
import styled from "styled-components";
import { Link as link } from "react-router-dom";
interface Props {
  to: string;
  children: React.ReactNode;
  color?: "success" | "cancel" | "info" | "alert";
  clicked?: () => void;
}
const typeToColor = (color?: "success" | "cancel" | "info" | "alert") => {
  switch (color) {
    case "success":
      return "--green";
    case "cancel":
      return "--red";
    case "info":
      return "--blue";
    case "alert":
      return "--yellow";
    default:
      return "--grey-light-1";
  }
};
const Lnk = styled(link)`
  text-decoration: none;
  text-transform: capitalize;
  cursor: pointer;
  padding: 1px 3px;
  /* height: var(--input-height); */
  display: inline-block;
  border-bottom: 2px solid transparent;
  font: inherit;
  font-weight: 600;
  color: var(${({ color }: Props) => typeToColor(color)});
  text-align: center;
  transition: all 0.3s;
  &:hover {
    border-color: var(${({ color }: Props) => typeToColor(color)});
  }
`;

const Link = ({ to, children, color, clicked }: Props) => {
  return (
    <Lnk to={to} color={color} onClick={clicked}>
      {children}
    </Lnk>
  );
};

export default Link;
