import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import GroceryCard from "../components/Layout/GroceryCard";
import Heading from "../components/Typography/Heading";
import Paragraph from "../components/Typography/Paragraph";
import Spacer from "../components/Utils/Spacer";
import Container from "../components/Wrappers/Container";
import Wrapper from "../components/Wrappers/Wrapper";
import Loader from "./../components/Animations/Loader";
import { isAuthenticated, getMyFavoriteGroceries } from "../api";
import { AppContext } from "../context/AppContext";

const GroceryGrid = styled.div`
  width: 100%;
  padding: 2rem 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  align-items: flex-start;
  gap: 2rem;
`;

const Favorites = () => {
  // const [groceries, setGroceries] = useState([] as IGrocery[]);
  const [loading, setLoading] = useState(false);
  const { favoriteData } = useContext(AppContext);
  const [favoriteGroceries, setFavoriteGroceries] = favoriteData;
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Bancas Favoritas | BAZARAQUI";
  }, []);

  useEffect(() => {
    const { user, token } = isAuthenticated();

    const init = async () => {
      if (!favoriteGroceries.length) {
        setLoading(true);
        const res = await getMyFavoriteGroceries(user.id, token);
        setFavoriteGroceries([...res]);
      } else {
        const { user, token } = isAuthenticated();
        const res = await getMyFavoriteGroceries(user.id, token);
        setFavoriteGroceries([...res]);
      }
      setLoading(false);
    };
    init();
    // eslint-disable-next-line
  }, []);

  return (
    <Wrapper>
      <Container>
        <Heading size="xl" weight="bold">
          Bancas Favoritas
        </Heading>
        <Spacer />
        <GroceryGrid>
          {!loading ? (
            favoriteGroceries.length ? (
              favoriteGroceries.map((grocery: IGrocery, i: number) => (
                <GroceryCard
                  key={i}
                  name={grocery.name}
                  image={grocery.logo}
                  description={grocery.about}
                  slug={grocery.slug}
                />
              ))
            ) : (
              <Paragraph>Nenhuma banca favorita!</Paragraph>
            )
          ) : (
            <Loader />
          )}
        </GroceryGrid>
      </Container>
    </Wrapper>
  );
};

export default Favorites;

//
