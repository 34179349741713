import React from "react";
import styled from "styled-components";
import PageSwap from "./../Animations/PageSwap";
interface Props {
  children: React.ReactNode;
  form?: boolean;
}
const Container = styled.div`
  min-height: 80vh;
  width: 100%;
  padding: ${({ form }: Props) => (form ? "1.8rem" : "unset")};
  @media only screen and (max-width: 600px) {
    animation: ${PageSwap} 0.3s ease-in-out;
    backface-visibility: hidden;
  }
`;
const Wrapper = ({ children, form }: Props) => {
  return <Container form={form}>{children}</Container>;
};

export default Wrapper;
