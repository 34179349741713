import Button from "../Generics/Button";
import logo from "./../../assets/logo.png";
import userPhoto from "./../../assets/placeholder150.png";
import { IoSearch, IoStorefrontOutline } from "react-icons/io5";
import IconWrapper from "../Wrappers/IconWrapper";
import { useContext, useEffect, useState } from "react";
import {
  NavWrapper,
  Nav,
  Navlink,
  NavButton,
  SearchBar,
  Search,
  Logo,
  Cta,
  Cta2,
  Links,
  Profile,
  UserPhoto,
} from "./StylesNavigation";
import { useHistory } from "react-router-dom";
import { isAuthenticated } from "../../api";
import { useMediaQuery } from "react-responsive";
import { AppContext } from "../../context/AppContext";
import styled from "styled-components";
import { FaCog } from "react-icons/fa";
import { FiHeart } from "react-icons/fi";

const LinkIcon = styled.div`
  /* padding: 1.5rem; */
  display: flex;
  align-items: center;
`;
const Navbar = () => {
  const [searchValue, setSearchValue] = useState("");
  const [user, setUser] = useState<IUser>();

  const history = useHistory();
  const isMobile = useMediaQuery({ maxWidth: 600 });
  const { uiUpdate } = useContext(AppContext);
  const [updateUi, ,] = uiUpdate;
  useEffect(() => {
    setUser(isAuthenticated().user);
  }, [updateUi]);
  const handleSearch = (event: any) => {
    event.preventDefault();
    history.push(`/search/${searchValue}`);
  };
  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    setSearchValue(event.currentTarget.value);
  };
  return (
    <NavWrapper>
      <Nav>
        <Navlink exact to="/">
          <Logo src={logo} alt="logotipo do bazaraqui" />
        </Navlink>
        <SearchBar onSubmit={handleSearch}>
          <Search
            onChange={handleChange}
            type="search"
            placeholder="pimento..."
            name="search"
          />
          <Button w0 color="success" name="pesquisar">
            <IconWrapper>
              <IoSearch />
            </IconWrapper>
          </Button>
        </SearchBar>
        {!isMobile ? (
          user && Object.keys(user).length !== 0 ? (
            <Links>
              {user.role === "admin" ? (
                <Navlink to="/dashboard">
                  <LinkIcon>
                    <IconWrapper>
                      <FaCog />
                    </IconWrapper>
                    &nbsp; <span>Admin</span>
                  </LinkIcon>
                </Navlink>
              ) : user.role === "seller" ? (
                <Navlink to="/my-grocery">
                  <LinkIcon>
                    <IconWrapper>
                      <IoStorefrontOutline />
                    </IconWrapper>
                    &nbsp; <span>Minha banca</span>
                  </LinkIcon>
                </Navlink>
              ) : null}
              <Navlink to="/favorites">
                <LinkIcon>
                  <IconWrapper>
                    <FiHeart />
                  </IconWrapper>
                  &nbsp; <span>Favoritas</span>
                </LinkIcon>
              </Navlink>

              <NavButton onClick={() => history.push("/profile")}>
                <Profile>
                  <UserPhoto
                    src={user ? user.photo : userPhoto}
                    alt="foto de perfil do usuario"
                  />
                  {user ? user.name.split(" ")[0] : ""}
                </Profile>
              </NavButton>
            </Links>
          ) : (
            <>
              <Navlink to="/login">
                <Cta>Entrar</Cta>
              </Navlink>
              <Navlink to="/signup">
                <Cta2>Criar conta</Cta2>
              </Navlink>
            </>
          )
        ) : null}

        {}
      </Nav>
    </NavWrapper>
  );
};

export default Navbar;
