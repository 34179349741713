import { useEffect } from "react";
import styled from "styled-components";
import Heading from "../components/Typography/Heading";
import Paragraph from "../components/Typography/Paragraph";
import Container from "../components/Wrappers/Container";
import Wrapper from "../components/Wrappers/Wrapper";

const Card = styled.div`
  padding: 1.8rem;
  margin-top: 4rem;
  background-color: var(--white);
  box-shadow: var(--shadow-smooth);
  text-align: center;
  padding-bottom: 4rem;
`;

const TermsAndConditions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `Termos e condições | Bazaraqui`;
    // eslint-disable-next-line
  }, []);
  return (
    <Wrapper>
      <Container>
        <Card>
          <Heading weight="bold">Termos</Heading>
          <Paragraph weight="normal">
            Bem-vindo ao BazarAqui! Estes Termos de Uso regem o uso do BazarAqui
            e fornecem informações sobre o BazarAqui, descritas abaixo. Ao criar
            uma conta do BazarAqui ou usar o BazarAqui, você concorda com estes
            termos. Concordamos em fornecer a você o Serviço BazarAqui. O
            Serviço inclui todos os produtos, recursos, aplicativos, serviços,
            tecnologias e software do BazarAqui que fornecemos para avançar na
            missão do BazarAqui: Conectar vendedores dos mercados e seus
            clientes de forma segura que permita a prevenção da COVID-19. O
            Serviço é composto pelos seguintes aspectos:
            <span className="info__title">
              Oferecendo oportunidades personalizadas para criar, conectar,
              comunicar, descobrir e compartilhar mercados, bancas, produtos e
              clientes.
            </span>
            Os vendedores constituem grande parte dos cidadãos do nosso país e a
            sua atividade coloca-os num dos grupos de maior risco de contrair
            COVID-19 devido ao elevado nível de contacto com clientes e
            dinheiro. Com a BazarAqui, queremos estreitar o relacionamento entre
            vendedores e clientes, compartilhando informações sobre mercados,
            estandes e produtos entre eles. Por isso, criamos sistemas que
            entendem quem são os moradores e mercados de um determinado
            município e usamos essas informações para ajudá-lo a se conectar com
            eles, encontrar, entrar em contato e compartilhar informações
            importantes para fazer suas compras, reduzindo o contato físico.
            Parte disso é destacar o conteúdo, recursos, feiras e barracas que
            lhe interessam e oferecem formas de vivenciar o BazarAqui, a partir
            de barracas que considera boas.
            <span className="info__title">
              Promover um ambiente positivo, inclusivo e seguro.
            </span>
            Desenvolvemos e usamos ferramentas e oferecemos recursos a nossos
            usuarios e vendedores que ajudam a tornar suas experiências
            positivas e inclusivas, inclusive quando achamos que eles podem
            precisar de ajuda. Também temos equipes e sistemas que trabalham
            para combater abusos e violações de nossos Termos e políticas, além
            de comportamentos prejudiciais e enganosos. Usamos todas as
            informações que temos, incluindo as suas, para tentar manter nossa
            plataforma segura. Também podemos compartilhar informações sobre uso
            indevido ou conteúdo prejudicial com órgãos policiais. Saiba mais na
            Política de dados.
            <span className="info__title">
              Desenvolver e usar tecnologias que nos ajudem a servir
              consistentemente nossa comunidade em crescimento.
            </span>
            Organizar e analisar informações para nossa crescente comunidade é
            essencial para o nosso Serviço. Grande parte do nosso Serviço está
            criando e usando tecnologias de ponta que nos ajudam a personalizar,
            proteger e melhorar nosso Serviço em uma escala incrivelmente grande
            para uma ampla comunidade global. Tecnologias como inteligência
            artificial e aprendizado de máquina nos dão o poder de aplicar
            processos complexos em nosso Serviço. As tecnologias automatizadas
            também nos ajudam a garantir a funcionalidade e a integridade do
            nosso serviço.
          </Paragraph>
        </Card>
      </Container>
    </Wrapper>
  );
};

export default TermsAndConditions;
