import { useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Link from "../components/Generics/Link";
import Paragraph from "../components/Typography/Paragraph";
import Container from "../components/Wrappers/Container";
import Wrapper from "../components/Wrappers/Wrapper";

const Card = styled.div`
  padding: 1.8rem;
  margin-top: 4rem;
  background-color: var(--white);
  box-shadow: var(--shadow-smooth);
  text-align: center;
  padding-bottom: 4rem;
`;
const BigText = styled.h1`
  font-size: 8rem;
  letter-spacing: 5px;
  font-weight: 700;
  color: var(--grey-dark-1);
`;
const returnMessage = (code: string) => {
  if (code === "403")
    return "O usuario não tem autorizacao para acessar esses recursos";
};

const ErrorPage = () => {
  const { errorCode } = useParams<{ errorCode: string }>();
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `Erro ${errorCode || 404}`;
    // eslint-disable-next-line
  }, []);
  return (
    <Wrapper>
      <Container>
        <Card>
          <BigText>{errorCode ? errorCode : 404}</BigText>
          <Paragraph>
            {errorCode
              ? returnMessage(errorCode)
              : "a pagina que pretende acessar nao existe"}
            . voltar para
            <Link color="info" to="/">
              Inicio
            </Link>
          </Paragraph>
        </Card>
      </Container>
    </Wrapper>
  );
};

export default ErrorPage;
