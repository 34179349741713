import "./App.css";
import Footer from "./components/Layout/Footer";
import Navbar from "./components/Layout/Navbar";
import Spacer from "./components/Utils/Spacer";
import { Route, Switch } from "react-router-dom";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Home from "./pages/Home";
import ErrorPage from "./pages/ErrorPage";
import Grocery from "./pages/Grocery";
import Seller from "./pages/Seller";
import BottomBar from "./components/Layout/BottomBar";
import { useMediaQuery } from "react-responsive";
import Profile from "./pages/Profile";
import Favorite from "./pages/Favorites";
import PasswordForgot from "./pages/PasswordForgot";
import PasswordReset from "./pages/PasswordReset";
import Search from "./pages/Search";
import PrivateRoute from "./components/Auth/PrivateRoute";
import SellerRoute from "./components/Auth/SellerRoute";
import AdminRoute from "./components/Auth/AdminRoute";
import UserUpdate from "./pages/UserUpdate";
import PasswordUpdate from "./pages/PasswordUpdate";
import SellerGrocery from "./pages/SellerGrocery";
import AddProduct from "./pages/AddProduct";
import Market from "./pages/Market";
import UpdateProduct from "./pages/UpdateProduct";
import { AppProvider } from "./context/AppContext";
import Dashboard from "./pages/DashBoard";
import SellerAndGrocery from "./pages/SellerAndGrocery";
import AddMarket from "./pages/AddMarket";
import useGoogleTracker from "./useGoogleTracker";
import UpdateGrocery from "./pages/UpdateGrocery";
import TermsAndConditions from "./pages/TermsAndConditions";
import About from "./pages/About";
import PrivacyPolicies from "./pages/PrivacyPolicies";
import UpdateMarket from "./pages/UpdateMarket";
import FAQs from "./pages/FAQs";
import CreateAdmin from "./pages/CreateAdmin";
const App = () => {
  useGoogleTracker();

  const isMobile = useMediaQuery({ maxWidth: 600 });
  return (
    <AppProvider>
      <Navbar />
      <Spacer size="xlg" />
      <Spacer size="md" />
      {isMobile ? <Spacer size="md" /> : <Spacer size="xlg" />}
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/login" component={Login} />
        <Route path="/about-us" component={About} />
        <Route path="/faqs" component={FAQs} />
        <Route path="/privacy-policies" component={PrivacyPolicies} />
        <Route path="/terms-and-conditions" component={TermsAndConditions} />
        <Route path="/signup" component={Signup} />
        <Route path="/grocery/:slug" component={Grocery} />
        <Route path="/search/:query" component={Search} />
        <PrivateRoute path="/profile" component={Profile} />
        <PrivateRoute path="/user-update/:content" component={UserUpdate} />
        <PrivateRoute path="/favorites" component={Favorite} />
        <SellerRoute path="/add-product" component={AddProduct} />
        <SellerRoute path="/my-grocery" component={SellerGrocery} />
        <SellerRoute path="/update-grocery" component={UpdateGrocery} />
        <SellerRoute path="/update-product/:slug" component={UpdateProduct} />
        <AdminRoute path="/seller/:id" component={Seller} />
        <AdminRoute path="/dashboard" component={Dashboard} />
        <AdminRoute path="/add-market" component={AddMarket} />
        <AdminRoute path="/market/:id" component={Market} />
        <AdminRoute path="/update-market/:id" component={UpdateMarket} />
        <AdminRoute path="/create-admin" component={CreateAdmin} />
        <AdminRoute
          path="/seller-and-grocery/:sellerId"
          component={SellerAndGrocery}
        />
        <Route path="/forgot-password" component={PasswordForgot} />
        <Route path="/update-password" component={PasswordUpdate} />
        <Route path="/reset-password" component={PasswordReset} />
        <Route path="/error/:errorCode" component={ErrorPage} />
        <Route component={ErrorPage} />
      </Switch>
      <Spacer size="xlg" />
      <Spacer size="xlg" />
      <BottomBar />
      <Footer />
    </AppProvider>
  );
};

export default App;
