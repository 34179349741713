import React from "react";
import styled from "styled-components";
interface Props {
  size?: "md" | "lg" | "xl" | "xxlg";
  children: React.ReactNode;
}
const sizeToNumber = (size?: "md" | "lg" | "xl" | "xxlg") => {
  if (size === "lg") return 4;
  if (size === "md") return 2.6;
  if (size === "xl") return 8;
  if (size === "xxlg") return 10;
  return 2;
};
const Icon = styled.div`
  color: currentColor;
  font-size: ${({ size }: Props) => sizeToNumber(size)}rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconWrapper = ({ size, children }: Props) => (
  <Icon size={size}>{children}</Icon>
);

export default IconWrapper;
