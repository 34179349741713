import { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import Select from "react-select";
import GroceryCard from "../components/Layout/GroceryCard";
import Heading from "../components/Typography/Heading";
import Paragraph from "../components/Typography/Paragraph";
import Wrapper from "../components/Wrappers/Wrapper";
import Container from "../components/Wrappers/Container";
import { getMarkets,
  // getGroceriesOnMarket,
  getGroceries } from "../api";
import Spacer from "../components/Utils/Spacer";
import Loader from "./../components/Animations/Loader";
import { Dark1Color } from "../components/Utils/TextColorUtils";
import { AppContext } from "../context/AppContext";
import { useHistory } from "react-router-dom";

const GroceryGrid = styled.div`
  width: 100%;
  padding: 2rem 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  align-items: flex-start;
  gap: 2rem;
`;
const SelectWrapper = styled.div`
  max-width: 300px;
`;
interface ISelect {
  label: string;
  value: string;
}

const Home = () => {
  const [selected, setSelected] = useState({} as ISelect);
  const [loading, setLoading] = useState(false);
  const { homeData } = useContext(AppContext);
  const [homeGroceriesHelper, setHomeGroceriesHelper] = useState([] as IGrocery[]);
  const [homeGroceries, setHomeGroceries, homeMarkets, setHomeMarkets] =
    homeData;
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Inicio | BAZARAQUI";
  }, []);
  useEffect(() => {
    const init = async () => {
      if (homeGroceries.length) {
        const res = await getMarkets();
        setHomeMarkets([...[{name: "Todos", id: "Todos"}].concat(res)]);
        findGroceries("");
      } else {
        setLoading(true);
        const res = await getMarkets();
        setHomeMarkets([...[{name: "Todos", id: "Todos"}].concat(res)]);
        findGroceries("");
      }
      setLoading(false);
    };
    init();
    // eslint-disable-next-line
  }, []);

  const findGroceries = async (marketId: string) => {
    let res;
    
    if (marketId) {
      // res = await getGroceriesOnMarket(marketId);

      let foundGroceries = homeGroceriesHelper;
  
      foundGroceries = foundGroceries.filter(
        (el: IGrocery) => el.market.id === marketId
      );
  
      marketId !== "Todos"
        ? setHomeGroceries(foundGroceries)
        : setHomeGroceries(homeGroceriesHelper);
    }
    else {
      res = await getGroceries();
      setHomeGroceriesHelper(res);
      setHomeGroceries([...res]);
    }
  };
  const options: any[] | undefined = homeMarkets.map((el: any) => {
    return { label: el.name, value: el.id };
  });
  const optionChange = (option: any) => {
    setSelected(option);
    findGroceries(option.value);
  };

  return (
    <Wrapper>
      <Container>
        <Heading weight="bold">
          <Dark1Color>Faça compras no seu mercado favorito</Dark1Color>
        </Heading>
        <Spacer size="sm" />
        <SelectWrapper>
          <Select options={options} onChange={optionChange} defaultValue={{label: "Seleccione um mercado"}} />
        </SelectWrapper>
        <Spacer />
        <GroceryGrid>
          {!loading ? (
            homeGroceries.length ? (
              homeGroceries.map((grocery: any, i: number) => (
                <GroceryCard
                  key={i}
                  name={grocery.name}
                  image={grocery.logo}
                  description={grocery.about}
                  slug={grocery.slug}
                  clicked={() => history.push(`/grocery/${grocery.slug}`)}
                />
              ))
            ) : (
              <Paragraph>
                <Dark1Color>
                  Nenhuma banca no mercado {selected.label}
                </Dark1Color>
              </Paragraph>
            )
          ) : (
            <Loader />
          )}
        </GroceryGrid>
      </Container>
    </Wrapper>
  );
};

export default Home;
