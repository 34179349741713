import styled from "styled-components";
import IconWrapper from "../Wrappers/IconWrapper";
import { GrFormClose } from "react-icons/gr";
import Paragraph from "../Typography/Paragraph";
import { FiPhone } from "react-icons/fi";
import { FaWhatsapp } from "react-icons/fa";
import Spacer from "../Utils/Spacer";
import { DarkColor, YellowColor } from "../Utils/TextColorUtils";

interface Props {
  grocery: string;
  productName: string;
  marketName: string;
  image: string;
  priceVariation?: IPrices[];
  contact: string;
  paymentMethods: string[];
  visible: boolean;
  close: () => void;
}
const Wrapper = styled.div`
  visibility: ${({ visible }: { visible: boolean }) =>
    visible ? "visible" : "hidden"};
  opacity: ${({ visible }: { visible: boolean }) => (visible ? "1" : "0")};
  z-index: 99;
  width: 100%;
  height: 100vh;
  font-size: 1.5rem;
  color: var(--grey-dark-1);
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  background-color: hsla(0, 0%, 0%, 0.7);
  transition: all 0.3s;
`;
const Card = styled.div`
  transform: ${({ visible }: { visible: boolean }) =>
    visible ? "translateY(0)" : "translateY(-20rem)"};
  top: 4rem;
  position: relative;
  background-color: var(--white);
  padding: 1.8rem;
  max-width: 45rem;
  min-width: 40rem;
  transition: all 0.3s;
  height: 90vh;
  margin: 0 auto;
  border-radius: var(--radius-md);
`;
const Content = styled.div`
  height: 95%;
  overflow-y: scroll;
  &:-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none; /* Firefox */
`;
const ProductImage = styled.img`
  width: 100%;
`;
const Closewrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
const CloseButton = styled.button`
  cursor: pointer;
  background-color: inherit;
  border: none;
  &:focus {
    outline: none;
    color: var(--red);
  }
`;
const Thin = styled.span`
  font-weight: 300;
`;

const Contacts = styled.div`
  display: flex;
  align-items: center;
`;
const CardFlag = styled.img`
  width: 4.5rem;
`;

const Payment = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 2rem;
`;

const Prices = styled.div`
  display: flex;
  flex-wrap: wrap;
  & > p {
    margin-right: 1rem;
  }
`;
const ProductPopup = ({
  grocery,
  productName,
  marketName,
  image,
  contact,
  priceVariation,
  paymentMethods,
  visible,
  close,
}: Props) => {
  return (
    <Wrapper
      visible={visible}
      onClick={(event) => {
        if (event.target === event.currentTarget) close();
      }}
    >
      <Card visible={visible}>
        <Closewrapper onClick={close}>
          <CloseButton>
            <IconWrapper size="lg">
              <GrFormClose />
            </IconWrapper>
          </CloseButton>
        </Closewrapper>
        <Content>
          <Paragraph weight="bold" size="lg">
            <DarkColor>{grocery}</DarkColor>
          </Paragraph>
          <Paragraph size="sm" weight="thin">
            {marketName}
          </Paragraph>
          <ProductImage src={image} alt={productName} />

          <Paragraph size="lg">
            <DarkColor>{productName}</DarkColor>
          </Paragraph>
          <Prices>
            {priceVariation &&
              priceVariation.map((price, iterator) => (
                <Paragraph weight="bold" size="lg" key={iterator}>
                  <DarkColor>{price.price} MT</DarkColor>
                  <Thin>/{price.name}</Thin>
                </Paragraph>
              ))}
          </Prices>

          <Spacer size="sm" />
          <Contacts>
            <IconWrapper>
              <FiPhone />
            </IconWrapper>
            &nbsp;&nbsp;contacto /&nbsp;
            <IconWrapper>
              <FaWhatsapp />
            </IconWrapper>
            &nbsp;&nbsp; whatsapp
          </Contacts>
          <Paragraph weight="bold">
            <YellowColor>+258 {contact}</YellowColor>
          </Paragraph>
          <Spacer size="sm" />

          <Paragraph>
            <DarkColor>Metodos de pagamento</DarkColor>
          </Paragraph>
          <Prices>
            {paymentMethods &&
              paymentMethods.map((el, i) => (
                <Payment key={i}>
                  <CardFlag
                    src={"/assets/payment-methods/" + el + ".png"}
                    alt={el + " icone"}
                  />
                  <Paragraph>{el}</Paragraph>
                </Payment>
              ))}
          </Prices>
        </Content>
      </Card>
    </Wrapper>
  );
};

export default ProductPopup;
