import { useContext, useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import styled from "styled-components";
import InputText from "../components/Form/InputText";
import Button from "../components/Generics/Button";
import Paragraph from "../components/Typography/Paragraph";
import Heading from "../components/Typography/Heading";
import Spacer from "../components/Utils/Spacer";
import { login, authenticate, isAuthenticated } from "../api";
import Loader from "./../components/Animations/Loader";
import Alert from "../components/Generics/Alert";
import { LoginForm } from "../components/Form/FormStyles";
import { YellowColor } from "../components/Utils/TextColorUtils";
import { AppContext } from "../context/AppContext";
import Wrapper from "../components/Wrappers/Wrapper";

const Buttons = styled.div`
  display: flex;
  justify-content: space-around;
`;
const Login = () => {
  const [values, setValues] = useState({
    phoneNumber: "",
    password: "",
  });
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [alertShown, setAlertShown] = useState({
    message: "",
    status: false,
    type: "",
  });
  const { uiUpdate } = useContext(AppContext);
  const [updateUi, setUpdatUi] = uiUpdate;
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Iniciar Sessão | BAZARAQUI";
  }, []);
  const { phoneNumber, password } = values;
  const handleCloseAlert = () => {
    setAlertShown({ ...alertShown, message: "", status: false, type: "" });
  };
  const handleShowAlert = (
    message: string,
    type: "alert" | "cancel" | "success"
  ) => {
    setAlertShown({
      ...alertShown,
      message: message,
      status: true,
      type: type,
    });
  };
  const handleChange =
    (input: string) => (event: React.FormEvent<HTMLInputElement>) => {
      // event.currentTarget.value = event.currentTarget.value.replace(/\D/, "");
      setValues({
        ...values,
        [input]: event.currentTarget.value,
      });
    };

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (!phoneNumber.match(/^(84|85|86|87|83|82)\d{7}$/))
      return handleShowAlert(
        "ERRO: o número de celular tem de ser Moçambicano",
        "cancel"
      );
    setLoading(true);
    const res = await login({ phoneNumber, password });
    setLoading(false);
    if (res.status === "fail" || res.status === "error") {
      handleShowAlert("Número de celular ou palavra passe incorectos", "alert");
    } else {
      handleShowAlert("Inicio de sessão efectuado com sucesso", "success");
      authenticate({ token: res.token, user: res.data.user });
      setUpdatUi(!updateUi);
    }
  };

  const redirectUser = () => {
    if (isAuthenticated()) {
      return <Redirect to="/" />;
    }
  };

  return (
    <Wrapper form>
      <Alert
        message={alertShown.message}
        close={handleCloseAlert}
        visible={alertShown.status}
        type={alertShown.type}
      />
      {loading ? <Loader /> : null}

      <LoginForm onSubmit={handleSubmit}>
        <Heading size="xl" weight="bold">
          Iniciar Sessão
        </Heading>
        <Spacer size="xxlg" />
        <Spacer size="xxlg" />
        <InputText
          type="number"
          name="phone"
          placeholder="ex: 841231234"
          label="Número de celular"
          changed={handleChange("phoneNumber")}
          maximumLength={9}
          mandatory
        />
        <Spacer size="md" />
        <InputText
          type="password"
          name="password"
          placeholder="insira a sua senha"
          label="senha"
          changed={handleChange("password")}
          mandatory
        />

        <Spacer size="xlg" />
        <Buttons>
          <Button color="alert">Iniciar sessão</Button>
          <Button
            action={() => {
              history.push("/signup");
            }}
          >
            <YellowColor>criar conta</YellowColor>
          </Button>
        </Buttons>
        <Spacer size="md" />
        <Paragraph size="sm" weight="thin">
          Problemas ao iniciar a sessão?{" "}
          <Link to="/forgot-password"> Esqueci-me da senha</Link>
        </Paragraph>
      </LoginForm>
      {redirectUser()}
    </Wrapper>
  );
};

export default Login;
