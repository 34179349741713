import styled from "styled-components";

interface Props {
  children: React.ReactNode;
  clicked?: (param?: any) => void;
}
const Btn = styled.button`
  cursor: pointer;
  border: none;
  color: var(--white);
  z-index: 999;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 10rem;
  right: 1.5rem;
  background-color: var(--yellow);
  height: 6rem;
  width: 6rem;
  border-radius: 40rem;
  box-shadow: var(--shadow-smooth);
  &:focus {
    outline: none;
  }
`;
const FloatButton = ({ children, clicked }: Props) => {
  return <Btn onClick={clicked}>{children}</Btn>;
};

export default FloatButton;
