import { FiUpload } from "react-icons/fi";
import styled from "styled-components";
import Paragraph from "../Typography/Paragraph";
import IconWrapper from "../Wrappers/IconWrapper";
import { Label } from "./FormStyles";
import Spacer from './../Utils/Spacer'
interface Props {
  photoUrl: string;
  label: string;
  changed: (param?: any) => void;
}
const ImageInput = styled.input`
  display: none;
`;
const ImagePreview = styled.img`
  border-radius: var(--radius-md);
  width: 35rem;
`;
const ImageLabel = styled.label`
  cursor: pointer;
  position: relative;
`;
const FloatingText = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
  position: absolute;
  top: -250%;
`;
const Selector = styled.div`
  background-color: var(--white);
  padding: 4px;
  border-radius: 2px;
`;
const ImagePicker = ({ label, photoUrl, changed }: Props) => {
  return (
    <>
      <Paragraph>
        <Label>{label}</Label>
      </Paragraph>
      <ImageLabel htmlFor="imageInput">
        <FloatingText>
          <Selector>
            <IconWrapper>
              <FiUpload />
            </IconWrapper>
            Clique para alterar a foto
          </Selector>
        </FloatingText>
        <ImagePreview src={photoUrl} />
      </ImageLabel>
      <ImageInput
        id="imageInput"
        required
        type="file"
        onChange={changed}
        accept="image/*"
      />
      <Spacer />
    </>
  );
};

export default ImagePicker;
