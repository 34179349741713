import { createContext, useState } from "react";

const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [updateUi, setUpdateUi] = useState(false);
  const [homeGrceries, setHomeGroceries] = useState([]);
  const [homeMarkets, setHomeMarkets] = useState([]);
  const [favoriteGroceries, setFavoriteGroceries] = useState([]);
  const [appUser, setAppUser] = useState({});
  const [productsAdmin, setProductsAdmin] = useState([]);
  const [productsSeller, setProductsSeller] = useState([]);
  const [groceriesAdmin, setGroceriesAdmin] = useState([]);
  const [sellersAdmin, setSellersAdmin] = useState([]);
  const [marketsAdmin, setMarketsAdmin] = useState([]);
  const [statistics, setStatistics] = useState({});
  const [grocerySeller, setGrocerySeller] = useState({});
  const [sellerStatistics, setSellerStatistics] = useState({});
  const [alertShown, setAlertShown] = useState({
    status: false,
    message: "",
    type: "",
  });

  return (
    <AppContext.Provider
      value={{
        alertPopup: [alertShown, setAlertShown],
        uiUpdate: [updateUi, setUpdateUi],
        homeData: [homeGrceries, setHomeGroceries, homeMarkets, setHomeMarkets],
        favoriteData: [favoriteGroceries, setFavoriteGroceries],
        userData: [appUser, setAppUser],
        sellerData: [
          productsSeller,
          setProductsSeller,
          sellerStatistics,
          setSellerStatistics,
          grocerySeller,
          setGrocerySeller,
        ],
        adminData: [
          productsAdmin,
          setProductsAdmin,
          groceriesAdmin,
          setGroceriesAdmin,
          sellersAdmin,
          setSellersAdmin,
          marketsAdmin,
          setMarketsAdmin,
          statistics,
          setStatistics,
        ],
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
