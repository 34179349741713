import styled from "styled-components";
import Heading from "../components/Typography/Heading";
import Container from "../components/Wrappers/Container";
import Wrapper from "../components/Wrappers/Wrapper";
import SummaryCard from "../components/Layout/SummaryCard";
import { BiStore } from "react-icons/bi";
import { GoPlus } from "react-icons/go";
import Spacer from "../components/Utils/Spacer";
import { useEffect, useState } from "react";
import IconWrapper from "../components/Wrappers/IconWrapper";
import { useHistory, useParams } from "react-router-dom";
import { Buttons } from "../components/Form/FormStyles";
import Paragraph from "../components/Typography/Paragraph";
import { LightColor, RedColor } from "../components/Utils/TextColorUtils";
import MarketImagePlaceholder from "./../assets/logo.png";
import Outline from "../components/Generics/Outline";
import GroceryCard from "../components/Layout/GroceryCard";
import { blockMarket, getMarkets, isAuthenticated, unblockMarket } from "../api";
import Loader from "./../components/Animations/Loader";
import Button from "../components/Generics/Button";
import Prompt from "../components/Generics/Prompt";

interface IStats {
  totalGroceries: number;
  totalProducts: number;
  dailyVisits: number;
}
interface IParams {
  id: string;
}
const Grid = styled.div`
  width: 100%;
  padding: 2rem 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  align-items: center;
  gap: 2rem;
`;

const Summary = styled.div`
  padding: 2rems;
  display: flex;
  width: 100%;
  overflow: auto;
  white-space: nowrap;
`;
const FloatButton = styled.button`
  border: none;
  color: var(--white);
  z-index: 999;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 10rem;
  right: 1.5rem;
  background-color: var(--yellow);
  height: 6rem;
  width: 6rem;
  border-radius: 40rem;
  box-shadow: var(--shadow-smooth);
  &:focus {
    outline: none;
  }
`;
const MarketCover = styled.img`
  width: 100%;
  border-radius: var(--radius-md);
`;
const ImageWrapper = styled.div`
  max-width: 50rem;
  width: 100%;
`;
const Market = () => {
  const [statistics, setStatistics] = useState({} as IStats);
  const [market, setMarket] = useState({} as IMarket);
  const [groceries, setGroceries] = useState([] as IGrocery[]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { id } = useParams<IParams>();
  const [promptShown, setPromptShown] = useState(false);
  const [action, setAction] = useState("");
  const showPrompt = (action: string) => {
    setPromptShown(true);
    setAction(action);
  };
  const removePrompt = () => {
    setPromptShown(false);
  };
  const { totalGroceries } = statistics;
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Minha Banca | BAZARAQUI";
  }, []);

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      const res = await getMarkets(`/${id}`);
      setMarket(res);
      setGroceries(res.groceries);

      // let $totalProducts = 0;
      let $totalGroceries = res.groceries.length;

      setStatistics({ ...statistics, totalGroceries: $totalGroceries });
      setLoading(false);
    };
    init();
    // eslint-disable-next-line
  }, [id]);
  
  const handleSubmit = async () => {
    const { token } = isAuthenticated();
    setLoading(true);
    let res: any;
    if(action === "disable") {
      res = await blockMarket(market.id, token);
    }else res = await unblockMarket(market.id, token);
    setMarket({ ...market, active: !market.active });
    setLoading(false);
    console.log(res)
  }

  return (
    <>
      <Wrapper>
        <Prompt
          onConfirm={handleSubmit}
          message={`${action === "disable" ? "DESABILITAR": "HABILITAR"} ${market.name}?\nTodas as bancas serão ${action === "disable" ? "DESABILITADAS": "HABILITADAS"} também!`}
          visible={promptShown}
          close={removePrompt}
        />
        <Container>
          <Paragraph size="lg" weight="thin">
            {market.province}, {market.district}, {market.address}
          </Paragraph>
          <Heading size="xxl" weight="bolder">
            {market.name}
          </Heading>
          <ImageWrapper>
            <MarketCover src={MarketImagePlaceholder} />
          </ImageWrapper>
          <Heading size="xl" weight="bold">
            Sumário
          </Heading>
          <Summary>
            {/* <SummaryCard name="Visitas diarias" value={0} icon={<BiTrendingUp />} /> */}
            {/* <SummaryCard name="Produtos" value={totalProducts || 0} icon={<BiTrendingUp />} /> */}
            <SummaryCard
              name="Bancas"
              value={totalGroceries || 0}
              icon={<BiStore />}
            />
            {/* <SummaryCard name="Bloqueadas" value={69} icon={<BiStore />} /> */}
            {/* <SummaryCard name="Vendedores" value={69} icon={<HiUserGroup />} /> */}
            {/* <SummaryCard name="Compradores" value={69} icon={<HiOutlineUserGroup />} /> */}
            {/* <SummaryCard name="Bloqueados" value={69} icon={<RiUserUnfollowLine />} /> */}
          </Summary>
          <Spacer />
          <Heading size="lg" weight="bold">
            gerência
          </Heading>
          <Spacer />
          <Buttons>
            <Outline color="alert" action={() =>history.push(`/update-market/${market.id}`)}>Alterar dados</Outline>
            {market.active ?
              <Button action={() => showPrompt("disable")}>
                <RedColor>Desabilitar</RedColor>
              </Button>
              :
              <Button color="success" action={() => showPrompt("enable")}>
                Habilitar
              </Button>}
          </Buttons>
          {/* <InputText
            type="search"
            name="query"
            placeholder="procurar bancaa"
            label=""
            changed={() => {}}
          />
          <Spacer />
          <Buttons>
            <Button
              color="alert"
              action={() => {
                history.push(`/ùpdate-market/{market.id}`);
              }}
            >
              procurar
            </Button>
          </Buttons> */}
          <Spacer size="xlg" />
          <Paragraph weight="thin">
            <LightColor>Bancas encontradas</LightColor>
          </Paragraph>
          <Grid>
            {groceries.length ? groceries.map((grocery, i) => (
              <GroceryCard
                key={i}
                name={grocery.name}
                image={grocery.logo}
                description={grocery.about}
                slug={grocery.slug}
              />
            )) : <Paragraph>Nenhuma Banca</Paragraph>}
          </Grid>

          <FloatButton
            onClick={() => {
              history.push("/add-market");
            }}
          >
            <IconWrapper size="md">
              <GoPlus />
            </IconWrapper>
          </FloatButton>
        </Container>
        {loading ? <Loader /> : null}
      </Wrapper>
    </>
  );
};

export default Market;
