import { Link } from "react-router-dom";
import styled from "styled-components";
import Heading from "../Typography/Heading";
import Paragraph from "../Typography/Paragraph";
import Spacer from "../Utils/Spacer";
import logo from "./../../assets/logo-invert.png";

const FootWrapper = styled.div`
  width: 100%;
  /* height: 25rem; */
  background-color: var(--grey-dark);
  @media only screen and (max-width: 600px) {
    display: none;
  }
`;
const Logo = styled.img`
  height: 4.5rem;
`;
const Foot = styled.footer`
  padding: 3rem 1.5rem;
  color: var(--grey-light);
  font-size: var(--font-sm);
  font-weight: 400;
  max-width: 1000px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 auto;
  flex-wrap: wrap;
`;
const FootContainer = styled.div`
  max-width: 30rem;
`;
const FootLink = styled(Link)`
  cursor: pointer;
  position: relative;
  color: var(--white);
  text-decoration: none;
  transition: var(--transition-normal);
  display: flex;
  align-items: center;
  &:hover {
    color: var(--green);
  }
  &:not(:last-child) {
    margin-bottom: 0.2rem;
  }
`;
const FootLinkNative = styled.a`
  cursor: pointer;
  position: relative;
  color: var(--white);
  text-decoration: none;
  transition: var(--transition-normal);
  display: flex;
  align-items: center;
  &:hover {
    color: var(--green);
  }
  &:not(:last-child) {
    margin-bottom: 0.2rem;
  }
`;
const PartnerLogo = styled.img`
  width: ${({ small }: { small?: boolean }) => (small ? "5rem" : "10rem")};
`;
const Partner = styled.div`
  padding: 1rem;
  background-color: var(--white);
  border-radius: var(--radius-md);
  color: var(--grey-dark);
  max-width: 20rem;
  &:first-child {
    cursor: pointer;
  }
`;
const Footer = () => {
  return (
    <FootWrapper>
      <Foot>
        <FootContainer>
          <FootLink to="/">
            <Logo src={logo} alt="logotipo do bazaraqui" />
          </FootLink>
          <Spacer size="sm" />
          <Paragraph weight="thin">Ligue e fale conosco</Paragraph>
          <Paragraph weight="bold">+258 84 000 0000</Paragraph>
          <Paragraph weight="bold">+258 84 000 0000</Paragraph>
        </FootContainer>
        <FootContainer>
          <Heading weight="normal">As Nossas Redes sociais</Heading>
          <FootLinkNative href="https://www.facebook.com" target="_blank">
            Facebook
          </FootLinkNative>
          <FootLinkNative href="https://www.twitter.com" target="_blank">
            Twitter
          </FootLinkNative>
          <FootLinkNative href="https://www.instagram.com" target="_blank">
            Instagram
          </FootLinkNative>
        </FootContainer>
        <FootContainer>
          <Heading weight="normal">BazarAqui</Heading>
          <FootLink to="/about-us">Sobre Nós</FootLink>
          <FootLink to="/privacy-policies">Política de Privacidade</FootLink>
          <FootLink to="/terms-and-conditions">Termos & Condições</FootLink>
          <FootLink to="/faqs">Perguntas Frequentes</FootLink>
        </FootContainer>
      </Foot>
      <Foot>
        <Partner
          onClick={() => (window.location.href = "https://linktr.ee/pro_g")}
        >
          <Paragraph size="md">Desenvolvedor</Paragraph>
          <Heading size="xxl" weight="bolder">
            PRO-G
          </Heading>
          <Paragraph weight="thin" size="sm">
            Equipa vencedora do Maputo hacking marketplace
          </Paragraph>
        </Partner>
        <Partner>
          <Paragraph size="md">Parceiro implementador:</Paragraph>
          <PartnerLogo small src="/assets/partners/uem.png" />
          <PartnerLogo src="/assets/partners/ciuem.png" />
        </Partner>
        <Partner>
          <Paragraph>Parceiro:</Paragraph>
          <Paragraph>Financiador</Paragraph>
          <PartnerLogo src="/assets/partners/pnud.png" />
        </Partner>
        <Partner>
          <Paragraph>Parceiro:</Paragraph>
          <Paragraph>Beneficiario</Paragraph>
          <PartnerLogo src="/assets/partners/cmcm.png" />
        </Partner>
        <Partner>
          <Paragraph>Parceiro de</Paragraph>
          <Paragraph>implementação</Paragraph>
          <PartnerLogo src="/assets/partners/anamm.png" />
        </Partner>
      </Foot>
    </FootWrapper>
  );
};

export default Footer;
