import React from "react";
import styled from "styled-components";

interface Props {
  children: React.ReactNode;
}
const Wrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 1rem;
  font-size: var(--font-md);
  font-weight: 400;
  color: var(--grey-dark);
`;
const Container = ({ children }: Props) => {
  return <Wrapper>{children}</Wrapper>;
};

export default Container;
