import { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Paragraph from "../components/Typography/Paragraph";
import Container from "../components/Wrappers/Container";
import Wrapper from "../components/Wrappers/Wrapper";

const Card = styled.div`
  padding: 1.8rem;
  margin-top: 4rem;
  background-color: var(--white);
  box-shadow: var(--shadow-smooth);
  text-align: center;
  padding-bottom: 4rem;
`;

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `Perguntas Frequentes | Bazaraqui`;
    // eslint-disable-next-line
  }, []);
  return (
    <Wrapper>
      <Container>
        <Card>
          <Paragraph weight="normal">
            <h1>Como instalar o BazarAqui no meu dispositivo?'</h1>`{" "}
            <h2>Android</h2>
            <h3>Chrome</h3>
            <p>
              Inicie o Chrome para e abra o{" "}
              <Link to="/"> site do BazarAqui</Link>. Toque no botão de menu e
              toque em Adicionar à tela inicial (Add to Home Screen). Você
              poderá inserir um nome para o atalho e, em seguida, o Chrome irá
              adicioná-lo à sua tela inicial.
            </p>
            <p>
              O ícone aparecerá na tela inicial como qualquer outro de
              aplicativo instalado no seu celular, para que você possa
              arrastá-lo e colocá-lo onde quiser. O Chrome para Android carrega
              o site como um “aplicativo da web” quando você toca no ícone,
              então ele terá sua própria entrada no alternador de aplicativos e
              não terá nenhuma interface do navegador.
            </p>
            <br></br>
            <h3>Firefox</h3>
            <p>
              No Firefox para Android pode fazer isso se você tocar no botão de
              menu, tocar na opção Página e tocar em Adicionar à tela inicial.
            </p>
            <br></br>
            <h3>Samsung Internet</h3>
            <p>
              Inicie o aplicativo de Internet da Samsung e navegue até o{" "}
              <Link to="/"> site do BazarAqui</Link>. Toque no ícone "Menu" para
              abrir o menu do navegador
            </p>
            <p>
              Toque em "Adicionar página a". O menu Adicionar página da Web
              atual a é exibido. Toque em "Tela inicial" neste menu. A caixa de
              diálogo Adicionar à tela inicial será exibida. Toque em
              "Adicionar" na caixa de diálogo quando terminar. Seu novo ícone de
              atalho com link para o BazarAqui será adicionado à tela inicial do
              seu dispositivo. Toque no ícone a qualquer momento para iniciar o
              aplicativo.
            </p>
            <br></br>
            <h2>iPhone, iPad, & iPod Touch</h2>
            <p>
              Inicie o navegador Safari no iOS da Apple e navegue até o{" "}
              <Link to="/"> site do BazarAqui</Link>. Toque no botão
              Compartilhar na barra de ferramentas do navegador - é o retângulo
              com uma seta apontando para cima. Está na barra na parte superior
              da tela em um iPad e na barra na parte inferior da tela em um
              iPhone ou iPod Touch. Toque no ícone Adicionar à tela inicial (Add
              to Home Screen) no menu Compartilhar.
            </p>
            <p>
              Você será solicitado a nomear o atalho antes de tocar no botão
              Adicionar. O atalho pode ser arrastado e colocado em qualquer
              lugar, inclusive em pastas de aplicativos - exatamente como um
              ícone de aplicativo normal.{" "}
              {/*Quando você toca no ícone, ele carrega o site em uma guia normal dentro do aplicativo do navegador Safari.*/}
            </p>
            <br></br>
            <h2>Windows Phone</h2>
            <p>
              O processo é semelhante no Windows Phone. Primeiro, abra o{" "}
              <Link to="/"> site do BazarAqui</Link> no Internet Explorer. Toque
              no botão Mais (…) e toque em Fixar na tela inicial no menu que
              aparece.
            </p>
            `
          </Paragraph>
        </Card>
      </Container>
    </Wrapper>
  );
};

export default About;

/* 5468697320776562736974652077617320646576656c6f706564206279207468652070726f2d6
7207465616d2073686172617520686172726973736f6e2c2068656c6c6f2c2068656c746f6e20616
e64206b656c76656e2074686520746f7020746965722070726f6772616d6d65727320696e206d6f7a*/
